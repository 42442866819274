import React from 'react'
import FormItem from './FormItem'
import LabelItem from './LabelItem'
import SelectItem from './SelectItem'

interface MenuProps {
  name: string
  value: string | number
}

type Props = {
  name: string
  label?: string
  placeHolder?: string
  onChange: (val: string, name: string) => void
  isError?: boolean
  value?: any
  isRequired?: boolean
  menu?: MenuProps[]
  allowClear?: boolean
  disabled?: boolean
  labelColor?: string
}

const AppSelect = (props: Props) => {
  const {
    label = '',
    name,
    isError = false,
    onChange,
    placeHolder = '',
    value,
    isRequired = false,
    menu = [],
    allowClear,
    disabled,
    labelColor,
  } = props

  return (
    <FormItem label="" status={isRequired && isError ? 'error' : ''} error={`กรุณาระบุ ${label}`}>
      {label && (
        <>
          <LabelItem label={isRequired ? `${label}*` : label} color={labelColor} /> <br />
        </>
      )}
      <SelectItem
        name={name}
        label={label}
        isRequired={true}
        value={value}
        menu={menu}
        placeHolder={placeHolder}
        onChange={(val: any): any => onChange(val, name)}
        allowClear={allowClear}
        disabled={disabled}
      />
    </FormItem>
  )
}

export default AppSelect
