import React, { useState, useEffect, useRef } from 'react'
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  Button,
  ModalFooter,
} from 'reactstrap'
import { connect } from 'react-redux'

import SimpleBar from 'simplebar-react'

import { withRouter } from 'react-router-dom'

//Import Components
import UserProfileSidebar from 'Components/chatvia/UserProfileSidebar'
import SelectContact from 'Components/chatvia/SelectContact'
import UserHead from './UserHead'
import ImageList from './ImageList'
import ChatInput from './ChatInput'
import FileList from './FileList'

//actions
import { setFullUserAction } from 'Store/chat/actions'
import { openUserSidebarAction } from 'Store/chat/actions'

//Import Images
import avatar1 from '../../../assets/images/users/avatar-1.jpg'
import { selectChatActiveUser } from 'Store/chat/selector'
import { selectUserSidebar } from 'Store/chat/selector'
import { ProfileAvatar } from 'Components/avatar'
import { findUserById } from 'Store/chat/function'
import { Col, Row } from 'antd'
import { PrimaryButton } from 'Components/button'
import styled from 'styled-components'
import { ConfirmDailog, LoadingView } from 'Components/modal'
import { formatDateTimeTh, formatTimeTh, isToday, replaceChatText } from 'helpers/format'
import { selectUserProfile } from 'Store/user/selector'
import { appConfig } from 'config'
import theme from 'Components/common/Theme'
import ImageLink from './ImageLink'
import ImageQuestion from './ImageQuestion'
import LoadingMessage from 'Components/modal/LoadingMessage'

function UserChat(props) {
  const ref = useRef()
  const msgButtomRef = useRef()

  const [modal, setModal] = useState(false)

  //demo conversation messages
  //userType must be required
  const [orgChatMessages, setOrgChatMessages] = useState([])
  const [orgActiveUser, setOrgActiveUser] = useState(0)
  const [chatMessages, setchatMessages] = useState([])
  const [confirmBook, setConfirmBook] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showButtomMessage, setShowButtomMessage] = useState(false)

  useEffect(() => {
    const activeUser = findUserById(props.recentChatList, props.active_user)
    setchatMessages(activeUser?.messages || [])
    const newMessage = activeUser?.messages || []
    if (newMessage.length !== orgChatMessages.length) {
      if (!newMessage[newMessage.length - 1]?.isTyping) {
        setOrgChatMessages(newMessage)
      }
    }
    // ref.current.recalculate()
    // if (ref.current.el) {
    //   ref.current.getScrollElement().scrollTop = ref.current.getScrollElement().scrollHeight
    // }
  }, [props.active_user, props.recentChatList])

  useEffect(() => {
    if (props.active_user?.id !== orgActiveUser) {
      setOrgActiveUser(props.active_user?.id)
    }
  }, [props.active_user])

  useEffect(() => {
    setTimeout(() => {
      ref.current.recalculate()
      if (ref.current.el) {
        ref.current.getScrollElement().scrollTop = ref.current.getScrollElement().scrollHeight
        setShowButtomMessage(false)
      }
    }, 500)
  }, [props.active_user && props.recentChatList && orgChatMessages])

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      ref.current.recalculate()
      if (ref.current.el) {
        ref.current.getScrollElement().scrollTop = ref.current.getScrollElement().scrollHeight
        setShowButtomMessage(false)
        setLoading(false)
      }
    }, 5000)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      ref.current.recalculate()
      if (ref.current.el) {
        ref.current.getScrollElement().scrollTop = ref.current.getScrollElement().scrollHeight
      }
    }, 100)
  }, [props.active_user])

  // useEffect(() => {
  // ref.current.recalculate()
  // if (ref.current.el) {
  //   ref.current.getScrollElement().scrollTop = ref.current.getScrollElement().scrollHeight
  // }
  // }, [chatMessages])

  // useEffect(() => {
  //   ref.current.recalculate()

  //   if (ref.current.el) {
  //     const chatInputHeight =
  //       (document.getElementsByClassName('chat-input-message-multiline')[0] &&
  //         document.getElementsByClassName('chat-input-message-multiline')[0]?.clientHeight) ||
  //       0
  //     const divHeight = ref.current.el.clientHeight
  //     const scrollHeight = ref.current.getScrollElement().scrollHeight
  //     const offset = ref.current.getScrollElement().scrollTop
  //     const scroll = scrollHeight - offset
  //     const realScroll = scroll - divHeight - chatInputHeight
  //     const isScroll = realScroll > 100
  //     consoleLog('realScroll', realScroll)
  //     if (realScroll <= 100) {
  //       scrolltoBottom()
  //     } else {
  //       if (isScroll) {
  //         setShowButtomMessage(true)
  //         if (msgButtomRef?.current) {
  //           // msgButtomRef?.current?.el.style.bottom = chatInputHeight + 'px'
  //           msgButtomRef.current.style.bottom = chatInputHeight + 'px'
  //         }
  //       }
  //     }

  //     // consoleLog('chatInputHeight', chatInputHeight)
  //     // consoleLog('chatContentHeight', chatContentHeight)
  //     // consoleLog('scrollHeight', scrollHeight)
  //     // consoleLog('offset', offset)
  //     // consoleLog('isScroll', isScroll)
  //     consoleLog('chatMessages', chatMessages)
  //   }

  //   // if (ref.current.el) {
  //   //   ref.current.getScrollElement().scrollTop = ref.current.getScrollElement().scrollHeight
  //   // }
  // }, [orgChatMessages])

  const toggle = () => setModal(!modal)

  const addMessage = (message, type) => {
    props.addMessage(message, type)
    setTimeout(() => {
      scrolltoBottom()
      setShowButtomMessage(false)
    }, 100)
  }

  function scrolltoBottom() {
    if (ref.current.el) {
      ref.current.getScrollElement().scrollTop = ref.current.getScrollElement().scrollHeight
    }
  }

  const deleteMessage = id => {
    const conversation = chatMessages

    const filtered = conversation.filter(function(item) {
      return item.id !== id
    })

    setchatMessages(filtered)
  }

  const onBook = () => {
    setConfirmBook(false)
    props.onBookFriend('book')
  }

  const ConfirmBookModal = (
    <ConfirmDailog
      title={`ยืนยันการรับงาน ?`}
      description={`คุณต้องการ "รับงาน" เพื่อโอนงานไปยัง งานที่มอบหมายให้ฉัน และเริ่มสนทนากับลูกค้าใช่หรือไม่`}
      isShow={confirmBook}
      confirmBtnTxt="รับงาน"
      handleOk={onBook}
      handleCancel={() => setConfirmBook(false)}
    />
  )

  const renderBookButton = () => {
    return (
      <ButtonWrapper>
        <Row justify="center">
          <Col md={12} xs={22}>
            <PrimaryButton
              text={
                props.limitBook ? `งานใน "มอบหมายให้ฉัน" ครบจำนวน ${appConfig.maxTask} งานแล้ว` : 'รับงาน'
              }
              onClick={() => setConfirmBook(true)}
              btnType="Primary"
              disabled={props.limitBook}
            />
            {props.limitBook && <WarningText>กรุณาทำการจบการสนทนาเพื่อรับงานต่อไปค่ะ</WarningText>}
          </Col>
        </Row>
      </ButtonWrapper>
    )
  }

  const activeUser = findUserById(props.recentChatList, props.active_user)
  const isNotAssign = activeUser?.userSocket?.assignID === ''
  const showBookButton = isNotAssign && props.isAgent

  return (
    <React.Fragment>
      {ConfirmBookModal}
      {!props.isAgent && <LoadingMessage isShow={loading} />}

      <div className={`user-chat w-100 ${props.isAgent ? 'agent' : ''}`}>
        <div className="d-lg-flex">
          <div className={'w-100 chat-input-parent'}>
            {/* render user head */}
            <UserHead />

            <SimpleBar
              style={{ maxHeight: '100%' }}
              ref={ref}
              className="chat-conversation p-3 p-lg-4"
              id="messages"
            >
              <ul className="list-unstyled mb-0">
                {chatMessages &&
                  chatMessages.map((chat, key) => {
                    const today = isToday(chat.time)
                    // const time = today ? formatTimeTh(chat.time) : formatDateTimeTh(chat.time)
                    const time = today ? formatTimeTh(chat.time) : formatTimeTh(chat.time)
                    return chat.isToday && chat.isToday === true ? (
                      <li key={'dayTitle' + key}>
                        <div className="chat-day-title">
                          <span className="title">วันนี้</span>
                        </div>
                      </li>
                    ) : activeUser?.isGroup === true ? (
                      <li key={key} className={chat.userType === 'sender' ? 'right' : ''}>
                        <div className="conversation-list">
                          <div className="chat-avatar">
                            {chat.userType === 'sender' ? (
                              <img src={avatar1} alt="chatvia" />
                            ) : activeUser?.profilePicture === 'Null' ? (
                              <div className="chat-user-img align-self-center me-3">
                                <div className="avatar-xs">
                                  <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                    {chat.userName && chat.userName.charAt(0)}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <img src={activeUser?.profilePicture || ''} alt="chatvia" />
                            )}
                          </div>
                          \
                          <div className="user-chat-content">
                            <div className="ctext-wrap">
                              <div className="ctext-wrap-content">
                                {chat.message && <p className="mb-0">{chat.message}</p>}
                                {chat.isImageMessage && (
                                  // image list component
                                  <ImageList images={chat.imageMessage} />
                                )}
                                {chat.isAutoMessage && (
                                  // image list component
                                  <ImageQuestion
                                    images={chat.imageMessage}
                                    onClick={() => {
                                      if (props.isAgent) return
                                      addMessage(chat.autoMessage, 'text')
                                    }}
                                  />
                                )}
                                {chat.isLinkMessage && (
                                  // image list component
                                  <ImageLink images={chat.imageMessage} link={chat.linkMessage} />
                                )}
                                {chat.fileMessage && (
                                  //file input component
                                  <FileList fileName={chat.fileMessage} fileSize={chat.size} />
                                )}
                                {chat.isTyping && (
                                  <div className="mb-0">
                                    typing
                                    <span className="animate-typing">
                                      <span className="dot ms-1"></span>
                                      <span className="dot ms-1"></span>
                                      <span className="dot ms-1"></span>
                                    </span>
                                  </div>
                                )}
                                {!chat.isTyping && (
                                  <p className="chat-time mb-0">
                                    <i className="ri-time-line align-middle"></i>{' '}
                                    <span className="align-middle">{time}</span>
                                  </p>
                                )}
                              </div>
                              {!chat.isTyping && (
                                <UncontrolledDropdown className="align-self-start">
                                  <DropdownToggle tag="a">
                                    <i className="ri-more-2-fill"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem>
                                      Copy <i className="ri-file-copy-line float-end text-muted"></i>
                                    </DropdownItem>
                                    <DropdownItem>
                                      Save <i className="ri-save-line float-end text-muted"></i>
                                    </DropdownItem>
                                    <DropdownItem onClick={toggle}>
                                      Forward <i className="ri-chat-forward-line float-end text-muted"></i>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => deleteMessage(chat.id)}>
                                      Delete <i className="ri-delete-bin-line float-end text-muted"></i>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              )}
                            </div>
                            {
                              <div className="conversation-name">
                                {chat.userType === 'sender' ? 'Patricia Smith' : chat.userName}
                              </div>
                            }
                          </div>
                        </div>
                      </li>
                    ) : (
                      <li key={key} className={chat.userType === 'sender' ? 'right' : ''}>
                        <div className="conversation-list">
                          {//logic for display user name and profile only once, if current and last messaged sent by same receiver
                          chatMessages[key - 1] ? (
                            chatMessages[key].userType === chatMessages[key - 1].userType ? (
                              <div className="blank-div"></div>
                            ) : (
                              <div>
                                {chat.userType === 'receiver' ? (
                                  <div className="chat-avatar">
                                    <ProfileAvatar
                                      size={36}
                                      name={activeUser?.name}
                                      logo={activeUser?.profilePicture}
                                    />
                                  </div>
                                ) : (
                                  <div className="blank-div"></div>
                                )}
                              </div>
                            )
                          ) : (
                            <div>
                              {chat.userType === 'receiver' ? (
                                <div className="chat-avatar">
                                  <ProfileAvatar
                                    size={36}
                                    name={activeUser?.name}
                                    logo={activeUser?.profilePicture}
                                  />
                                </div>
                              ) : (
                                <div className="blank-div"></div>
                              )}
                            </div>
                          )}

                          <div className="user-chat-content">
                            <div className="ctext-wrap">
                              <div className="ctext-wrap-content">
                                {chat.message && (
                                  // <span style={{ WhiteSpace: 'pre-line' }} className="mb-0">
                                  //   {chat.message.replace(/\r?\n/g, ' /n ')}
                                  // </span>
                                  <div
                                    className="mb-0"
                                    dangerouslySetInnerHTML={{
                                      __html: replaceChatText(chat.message || ''),
                                    }}
                                  />
                                )}
                                {chat.isImageMessage && (
                                  // image list component
                                  <ImageList images={chat.imageMessage} />
                                )}
                                {chat.isAutoMessage && (
                                  // image list component
                                  <ImageQuestion
                                    images={chat.imageMessage}
                                    onClick={() => {
                                      if (props.isAgent) return
                                      addMessage(chat.autoMessage, 'text')
                                    }}
                                  />
                                )}
                                {chat.isLinkMessage && (
                                  // image list component
                                  <ImageLink images={chat.imageMessage} link={chat.linkMessage} />
                                )}
                                {chat.isFileMessage && (
                                  //file input component
                                  <FileList fileName={chat.fileMessage} fileSize={chat.size} />
                                )}
                                {chat.isTyping && (
                                  <div className="mb-0">
                                    typing
                                    <span className="animate-typing">
                                      <span className="dot ms-1"></span>
                                      <span className="dot ms-1"></span>
                                      <span className="dot ms-1"></span>
                                    </span>
                                  </div>
                                )}

                                {!chat.isTyping && (
                                  <p className="chat-time mb-0">
                                    <i className="ri-time-line align-middle"></i>{' '}
                                    <span className="align-middle">{time}</span>
                                  </p>
                                )}
                              </div>
                              {/* {!chat.isTyping && (
              <UncontrolledDropdown className="align-self-start">
                <DropdownToggle tag="a">
                  <i className="ri-more-2-fill"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    Copy <i className="ri-file-copy-line float-end text-muted"></i>
                  </DropdownItem>
                  <DropdownItem>
                    Save<i className="ri-save-line float-end text-muted"></i>
                  </DropdownItem>
                  <DropdownItem onClick={toggle}>
                    Forward <i className="ri-chat-forward-line float-end text-muted"></i>
                  </DropdownItem>
                  <DropdownItem onClick={() => deleteMessage(chat.id)}>
                    Delete <i className="ri-delete-bin-line float-end text-muted"></i>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )} */}
                            </div>
                            {chatMessages[key - 1]
                              ? chatMessages[key].userType === chatMessages[key - 1].userType
                                ? null
                                : chat.userType === 'receiver' && (
                                    <div className="conversation-name">{activeUser?.name}</div>
                                  )
                              : chat.userType === 'receiver' && (
                                  <div className="conversation-name">{activeUser?.name}</div>
                                )}
                          </div>
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </SimpleBar>

            <Modal backdrop="static" isOpen={modal} centered toggle={toggle}>
              <ModalHeader toggle={toggle}>Forward to...</ModalHeader>
              <ModalBody>
                <CardBody className="p-2">
                  <SimpleBar style={{ maxHeight: '200px' }}>
                    <SelectContact handleCheck={() => {}} />
                  </SimpleBar>
                  <ModalFooter className="border-0">
                    <Button color="primary">Forward</Button>
                  </ModalFooter>
                </CardBody>
              </ModalBody>
            </Modal>

            {showBookButton ? (
              renderBookButton()
            ) : (
              <ChatInput
                onaddMessage={addMessage}
                onTyping={props.onTyping}
                activeUser={props.active_user}
                isAgent={props.isAgent}
              />
              // <>
              //   <ButtomMessageWrapper
              //     ref={msgButtomRef}
              //     onClick={() => {
              //       setShowButtomMessage(false)
              //       scrolltoBottom()
              //     }}
              //   >
              //     {showButtomMessage && (
              //       <ButtomMessageContent>
              //         {/* <span>{activeUser?.name}</span> &nbsp; */}
              //         {chatMessages[chatMessages.length - 1]?.isTyping ? (
              //           <>
              //             typing
              //             <span className="animate-typing">
              //               <span className="dot ms-1"></span>
              //               <span className="dot ms-1"></span>
              //               <span className="dot ms-1"></span>
              //             </span>
              //           </>
              //         ) : (
              //           <>
              //             {chatMessages &&
              //             chatMessages.length > 0 &&
              //             chatMessages[chatMessages.length - 1].isImageMessage === true ? (
              //               <i className="ri-image-fill align-middle me-1"></i>
              //             ) : null}
              //             {chatMessages &&
              //             chatMessages.length > 0 &&
              //             chatMessages[chatMessages.length - 1].isFileMessage === true ? (
              //               <i className="ri-file-text-fill align-middle me-1"></i>
              //             ) : null}
              //             {chatMessages && chatMessages.length > 0
              //               ? chatMessages[chatMessages.length - 1].message
              //               : null}
              //           </>
              //         )}
              //       </ButtomMessageContent>
              //     )}
              //   </ButtomMessageWrapper>
              //   <ChatInput
              //     onaddMessage={addMessage}
              //     onTyping={props.onTyping}
              //     activeUser={props.active_user}
              //     isAgent={props.isAgent}
              //   />
              // </>
            )}
          </div>

          <UserProfileSidebar
            activeUser={props.recentChatList[props.active_user]}
            isAgent={props.isAgent}
            isNotAssign={isNotAssign}
            onBookFriend={props.onBookFriend}
            isChaiyo={props.userProfile?.user_group === 'chaiyo'}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const ButtonWrapper = styled.div`
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
`

const WarningText = styled.div`
  font-size: ${theme.typography.body5};
  color: ${theme.colors.error};
  text-align: center;
  padding-top: 3px;
`

const ButtomMessageWrapper = styled.div`
  text-align: left;
  position: absolute;
  bottom: 90px;
  left: 0;
  right: 0;
  cursor: pointer;
`

const ButtomMessageContent = styled.div`
  background-color: #0404047d;
  width: 100%;
  padding: 8px 10px;
  color: #fff;
  .dot {
    color: #fff;
  }
`

const mapStateToProps = state => {
  return {
    active_user: selectChatActiveUser(state),
    userSidebar: selectUserSidebar(state),
    userProfile: selectUserProfile(state),
  }
}

export default withRouter(connect(mapStateToProps, { openUserSidebarAction, setFullUserAction })(UserChat))
