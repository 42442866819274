import withAPIAction from 'helpers/withAPIAction'
import {
  ListReq,
  DetailReq,
  UpdateReq,
  UpdateStatusReq,
  UpdateQrPartnerImageReq,
  CreateReq,
  PartnerStructureReq,
  PartnerCodeReq,
  PartnerInfoReq,
  ProfileReq,
} from './types'
import {
  getListAction,
  updateAction,
  getDetailAction,
  updateQrPartnerStatusAction,
  updateQrPartnerImageAction,
  createAction,
  getPartnerStructerAction,
  getPartnerCodeAction,
  getPartnerInfoAction,
  getPartnerProfileAction,
} from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'
import { getRequestString } from 'helpers/format'

export const createQrPartnerAPI = (data: CreateReq, token: string) => {
  return withAPIAction<CreateReq>({
    method: 'POST',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.createQrPartner,
    data,
    token,
  })(createAction)
}

export const getPartnerStructerAPI = (data: PartnerStructureReq, token: string) => {
  return withAPIAction<PartnerStructureReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getPartnerStructers}?${getRequestString(data)}`,
    data,
    token,
  })(getPartnerStructerAction)
}

export const getPartnerCodesAPI = (data: PartnerCodeReq, token: string) => {
  return withAPIAction<PartnerCodeReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getPartnerCodes}?${getRequestString(data)}`,
    data,
    token,
  })(getPartnerCodeAction)
}

export const getPartnerInfoAPI = (data: PartnerInfoReq, token: string) => {
  const endPoint = APIPath.getQrPartnerInfoByID.replace(':id', data.id.toString())
  return withAPIAction<PartnerInfoReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getPartnerInfoAction)
}

export const getPartnerProfileAPI = (data: ProfileReq, token: string) => {
  return withAPIAction<ProfileReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.getQrPartnerProfile,
    data,
    token,
  })(getPartnerProfileAction)
}

export const getQrPartnerListAPI = (data: ListReq, token: string) => {
  return withAPIAction<ListReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getQrPartnerList}?${getRequestString(data)}`,
    data,
    token,
  })(getListAction)
}

export const getQrPartnerDetailAPI = (data: DetailReq, token: string) => {
  const endPoint = APIPath.getQrPartnerByID.replace(':id', data.id.toString())
  return withAPIAction<DetailReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getDetailAction)
}

export const updateQrPartnerAPI = (data: UpdateReq, token: string) => {
  const endPoint = APIPath.updateQrPartner.replace(':id', data.id.toString())
  return withAPIAction<UpdateReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateAction)
}

export const updateQrPartnerStatusAPI = (data: UpdateStatusReq, token: string) => {
  const endPoint = APIPath.updateStatusQrPartner.replace(':id', data.id.toString())
  return withAPIAction<UpdateStatusReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateQrPartnerStatusAction)
}

export const updateQrPartnerImageAPI = (data: UpdateQrPartnerImageReq, token: string) => {
  return withAPIAction<UpdateQrPartnerImageReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.updateQrPartnerImage,
    data,
    token,
  })(updateQrPartnerImageAction)
}
