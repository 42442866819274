import React from 'react'
import { Cascader } from 'antd'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { PartnerStructureData } from 'Store/partner-qr/types'

type Props = {
  data: any
  handleChange?: (value: any, selectedOptions: any) => void
  onOpenEditCategory?: (open: boolean) => void
  readonly?: boolean
  categories: PartnerStructureData[]
  canAddCategory?: boolean
  hasLastchild?: boolean
  placeholder?: string
  changeOnSelect?: boolean
}

const PartnerStructer = (props: Props) => {
  const {
    data,
    handleChange = () => {},
    readonly = false,
    categories = [],
    onOpenEditCategory = () => {},
    hasLastchild = true,
    placeholder = 'เลือกหัวหน้างาน',
    changeOnSelect = true,
  } = props

  const options = [] as any[]
  let count = 0

  const renderChildren = (child: PartnerStructureData[], count: number) => {
    const options = [] as any[]
    if (child) {
      count++
      child.forEach((item: PartnerStructureData) => {
        options.push({
          value: item.user_id,
          label: item.agent_name || 'unknown',
          children: count <= 3 && item.childs ? renderChildren(item.childs, count) : null,
        })
      })
    }

    return options
  }

  categories.forEach((item: PartnerStructureData) => {
    count = 1
    options.push({
      value: item.user_id,
      label: item.agent_name || 'unknown',
      children: item.childs ? renderChildren(item.childs, count) : [],
    })
  })

  function filter(inputValue: any, path: any) {
    return path.some(
      (option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()?.trim()) > -1,
    )
  }

  //const val = data.group_id ? [data.group_id, data.category_id, data.sub_category_id] : undefined

  const dropdownRender = (menus: any, onOpen: any) => {
    return <div>{menus}</div>
  }

  return (
    <StyledCascader>
      <Cascader
        className="my-cascader"
        value={data}
        style={{ width: '100%' }}
        options={options}
        dropdownRender={(menu: any) => dropdownRender(menu, () => onOpenEditCategory(true))}
        onChange={handleChange}
        placeholder={placeholder}
        showSearch={{ filter }}
        changeOnSelect={changeOnSelect}
        disabled={readonly}
        allowClear={false}
      />
    </StyledCascader>
  )
}

const StyledCascader = styled.div`
  .my-cascader .ant-select-selector {
    height: 45px;
    border-radius: 6px;
  }
  height: 45px;
  width: 100%;
  .ant-input,
  .ant-select-selection-item {
    border-radius: 6px;
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
    font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
    color: #000;
    padding: 10px 26px 10px 16px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
  .ant-select-selection-search {
    padding-top: 6px;
  }
`

export default PartnerStructer
