import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import { CountData, Data, ListData, TaskLastest } from './types'

export const selectActionEvent = (state: RootState): Event => state.customerFileReducer.actionEvent

export const selectCustomerFileInfo = (state: RootState): Data | undefined =>
  state.customerFileReducer.customerInfo.data

export const selectCustomerFileEvent = (state: RootState): Event =>
  state.customerFileReducer.customerInfo.event

export const selectList = (state: RootState): ListData => state.customerFileReducer.customerList.data

export const selectListEvent = (state: RootState): Event => state.customerFileReducer.customerList.event

export const selectDetail = (state: RootState): Data | undefined =>
  state.customerFileReducer.customerDetail.detail

export const selectDetailEvent = (state: RootState): Event => state.customerFileReducer.customerDetail.event

export const selectLastCreateTask = (state: RootState): TaskLastest | undefined =>
  state.customerFileReducer.lastCreateTask

export const selectCountCustomerFile = (state: RootState): CountData => state.customerFileReducer.countData

export const selectTaskEvent = (state: RootState): Event => state.customerFileReducer.taskEvent

export const selectIsRefresh = (state: RootState): boolean => state.customerFileReducer.isRefresh
