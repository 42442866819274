import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, Modal, Row } from 'antd'
import Title from 'Components/text/Title'
import { PrimaryButton } from 'Components/button'
import { AppRangeDateItem } from 'Components/form'
import { MyTheme } from 'Components/common/Theme'
import Label from 'Components/text/Label'

interface Props {
  isShow: boolean
  handleOk?: (startDate: string, endDate: string) => void
  handleCancel?: () => void
  title?: string
  label?: string
  placeholder?: [string, string]
  confirmBtnTxt?: string
  cancelBtnTxt?: string
  loading?: boolean
  description?: string
}

const ConfirmExport = (props: Props) => {
  const {
    isShow = false,
    handleOk = () => {},
    handleCancel = () => {},
    title = 'ยืนยัน Export รายการคำสั่งซื้อ',
    placeholder = ['วันที่เริ่มต้น', 'วันที่สิ้นสุด'],
    confirmBtnTxt = 'ลบ',
    cancelBtnTxt = 'ยกเลิก',
    label = 'เหตุผลที่ไม่อนุมัติ',
    loading = false,
    description = '',
  } = props

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    setStartDate('')
    setEndDate('')
    setError(false)
  }, [isShow])

  const validateData = () => {
    if (startDate === '' || endDate === '') {
      setError(true)
      return false
    } else {
      setError(false)
      return true
    }
  }

  const onSubmit = () => {
    if (!validateData()) return
    handleOk(startDate, endDate)
  }

  const handleChangeDate = (startDate: string, endDate: string) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const footer = (
    <FooterStyled>
      <Row justify="center" gutter={16}>
        <Col md={8} xs={10}>
          <PrimaryButton text={cancelBtnTxt} btnType="Cancel" onClick={handleCancel} />
        </Col>
        <Col md={8} xs={10}>
          <PrimaryButton text={confirmBtnTxt} onClick={onSubmit} loading={loading} />
        </Col>
      </Row>
    </FooterStyled>
  )

  return (
    <ModalStyled visible={isShow} onCancel={handleCancel} footer={footer} centered>
      <ContentWrapper>
        <Title>{title}</Title>
        <AppRangeDateItem
          onChange={handleChangeDate}
          startDate={startDate}
          endDate={endDate}
          label={label}
          isRequired={true}
          isError={error}
          placeHolder={placeholder}
          maxDate={true}
        />
        {description && (
          <Row justify="start">
            <TextStyled>{description}</TextStyled>
          </Row>
        )}
      </ContentWrapper>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 6px;
  }
  .ant-modal-footer {
    border: none;
  }
`

const ContentWrapper = styled.div`
  text-align: center;
`

const FooterStyled = styled.div`
  margin-bottom: 15px;
`

const TextStyled = styled(Label)`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  text-align: left;
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
`

export default ConfirmExport
