import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import { ListData, Data, AgentStructureData, AgentCodeData, AgentInfoData, ProfileData } from './types'

export const selectListEvent = (state: RootState): Event => state.manageAgentReducer.listEvent

export const selectList = (state: RootState): ListData => state.manageAgentReducer.list

export const selectActionEvent = (state: RootState): Event => state.manageAgentReducer.actionEvent

export const selectDetail = (state: RootState): Data | undefined => state.manageAgentReducer.detail

export const selectDetailEvent = (state: RootState): Event => state.manageAgentReducer.detailEvent

export const selectAgentStructer = (state: RootState): AgentStructureData[] =>
  state.manageAgentReducer.partnerData

export const selectAgentCode = (state: RootState): AgentCodeData => state.manageAgentReducer.partnerCodes

export const selectAgentInfo = (state: RootState): AgentInfoData | undefined =>
  state.manageAgentReducer.partnerInfo

export const selectAgentProfile = (state: RootState): ProfileData | undefined =>
  state.manageAgentReducer.partnerProfile
