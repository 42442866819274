import React, { useEffect } from 'react'
import { Col, Empty, Row, Timeline } from 'antd'
import { DetailDrawer } from 'Components/common/MainLayout'
import styled from 'styled-components'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { LoadingView } from 'Components/modal'
import theme, { MyTheme } from 'Components/common/Theme'
import {
  firstUpperCase,
  formatDateTime,
  getCustomerTaskStatus,
  getSaleTaskStatus,
  getTaskLogsStatus,
} from 'helpers/format'
import Label from 'Components/text/Label'
import LinkText from 'Components/text/LinkText'
import { getTaskLogsAPI } from 'Store/chaiyo-customer/api'
import { LogData, LogReq } from 'Store/chaiyo-customer/types'
import { selectLoginData } from 'Store/user/selector'
import { selectLogsData, selectLogsEvent } from 'Store/chaiyo-customer/selector'

type Props = {
  isShow: boolean
  onClose: () => void
  id: number
} & ReduxProps

const Logs = (props: Props) => {
  const { isShow, onClose, id, loginData, list, event } = props

  const dispatch = useDispatch()

  const onGetDataList = (): void => {
    const req: LogReq = {
      id: id,
    }
    dispatch(getTaskLogsAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    if (isShow) {
      onGetDataList()
    }
  }, [isShow, id])

  const getTimeLines = () => {
    const timeline = [] as any
    list &&
      list.map((item: LogData, index: number) => {
        const status = getTaskLogsStatus(item.status)
        timeline.unshift(
          <Timeline.Item key={index} dot={<StartDot style={{ backgroundColor: status.color }} />}>
            {item.user_type.toUpperCase()} : {status.text}
            <br />
            <LabelStyle>วัน/เวลา : {formatDateTime(item.created_at)}</LabelStyle> <br />
            <LabelStyle>โดย : {item.user_fullname}</LabelStyle>
          </Timeline.Item>,
        )
      })
    return timeline
  }

  return (
    <DetailDrawer
      title={'สถานะงาน'}
      width={'30%'}
      onCloseDrawer={onClose}
      visible={isShow}
      footer={null}
      maskClosable={true}
    >
      <LoadingView isShow={event.IsLoading} />
      <Row>
        <Col span={24}>
          {list && list.length > 0 ? (
            <Timeline>{getTimeLines()}</Timeline>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Col>
      </Row>
    </DetailDrawer>
  )
}

const LabelStyle = styled(Label)`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
`

const StartDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectLogsEvent(state),
    list: selectLogsData(state),
    loginData: selectLoginData(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(Logs)
