import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { Col, Row, Space, Switch } from 'antd'
import { PrimaryButton } from 'Components/button'
import {
  formValidation,
  formatDateTime,
  getAgentStatus,
  getBankName,
  getStatus,
  getMenuValue,
} from 'helpers/format'
import { DetailReq, UpdateReq, UpdateStatusReq } from 'Store/manage-agent/types'
import {
  getAgentCodesAPI,
  getQrAgentDetailAPI,
  updateQrAgentAPI,
  updateQrAgentStatusAPI,
} from 'Store/manage-agent/api'
import {
  selectActionEvent,
  selectDetail,
  selectDetailEvent,
  selectAgentCode,
} from 'Store/manage-agent/selector'
import { clearEventAction } from 'Store/manage-agent/actions'
import EventModal from 'Components/modal/EventModal'
import LoadingView from 'Components/modal/LoadingView'
import { DetailDrawer } from 'Components/common/MainLayout'
import { ConfirmDailog, ConfirmQuestion } from 'Components/modal'
import { AgentApproveStatus, menuChaiyoUserAgentType, Status } from 'helpers/constanst'
import { selectLoginData } from 'Store/user/selector'
import { InfoItem } from 'Components/text'
import BoxWrapper from 'Components/common/Layout/BoxWrapper'
import { AgentQrCode, StatusTag } from 'Components/utilities'

type Props = ReduxProps & {
  visible: boolean
  onCloseDrawer: () => void
  onSuccess?: () => void
  id?: number
  isAdmin?: boolean
}

const AgentQrDetail: React.FunctionComponent<Props> = props => {
  const {
    onCloseDrawer,
    visible,
    event,
    onSuccess = () => {},
    id = 0,
    detail,
    detailEvent,
    loginData,
    isAdmin = false,
    partnerCodes,
  } = props

  const isPending = detail?.partner_status === AgentApproveStatus.PENDING
  const isApprove = detail?.partner_status === AgentApproveStatus.APPROVE

  const requireFeild = ['status'] as string[]
  const initForm = {
    status: Status.ACTIVE,
  } as UpdateReq
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const [formError, setFormError] = useState([] as string[])
  const [rejectDialog, setRejectDialog] = useState(false)
  const [approveDialog, setApproveDialog] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!visible) {
      setFormError([])
    }
    if (visible && id) {
      onGetDetail()
      getMasterData()
    }
  }, [visible])

  useEffect(() => {
    if (id > 0) {
      const form = {
        status: detail?.status,
      } as UpdateReq
      setFormData({ ...form })
    }
  }, [detail])

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const getMasterData = (): void => {
    dispatch(getAgentCodesAPI({}, loginData?.access_token || ''))
  }

  const onGetDetail = (): void => {
    const req: DetailReq = {
      id: id,
    }
    dispatch(getQrAgentDetailAPI(req, loginData?.access_token || ''))
  }

  const onUpdate = (status: Status): void => {
    if (!validateForm()) return
    const req: UpdateReq = {
      status,
      id: id,
    }
    dispatch(updateQrAgentAPI(req, loginData?.access_token || ''))
  }

  const onUpdateStatus = (status: AgentApproveStatus, reason: string): void => {
    const req: UpdateStatusReq = {
      id,
      partner_status: status,
      reason,
    }
    dispatch(updateQrAgentStatusAPI(req, loginData?.access_token || ''))
  }

  const onReject = (reason: string): void => {
    onUpdateStatus(AgentApproveStatus.REJECT, reason)
  }

  const onApprove = (): void => {
    onUpdateStatus(AgentApproveStatus.APPROVE, '')
  }

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    setFormError([...error])
    return error.length > 0 ? false : true
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventSuccess = (): void => {
    clearEvent()
    onGetDetail()
    onSuccess()
    if (rejectDialog) {
      setRejectDialog(false)
    }
    if (approveDialog) {
      setApproveDialog(false)
    }
  }

  // const editFooter = (
  //   <Space size="middle" align="baseline">
  //     <PrimaryButton text="ยกเลิก" btnType="Cancel" onClick={onCloseDrawer} />
  //     <PrimaryButton text="บันทึกข้อมูล" onClick={onUpdate} />
  //   </Space>
  // )

  const approveFooter = (
    <Space size="middle" align="baseline">
      <PrimaryButton
        text="ไม่อนุมัติ"
        btnType="Reject"
        onClick={() => {
          setRejectDialog(true)
        }}
      />
      <PrimaryButton
        text="อนุมัติ"
        btnType="Approve"
        onClick={() => {
          // if (detail?.agent_name === '' || (detail?.commission || 0) <= 0) {
          //   message.error('กรุณาแก้ไขข้อมูลชื่อทีมและเรทคอมมิชชั่น ให้ครบถ้วน ก่อนอนุมัติตัวแทน')
          //   return
          // }
          setApproveDialog(true)
        }}
      />
    </Space>
  )

  const renderInfo = (title: string, value: any, span: number = 15) => {
    return <InfoItem title={title} value={value} span={span} />
  }

  const renderStatus = (value: any) => {
    const result = getAgentStatus(value)
    return <StatusTag color={result.color} text={result.text} />
  }

  const renderInfoRow = (item1: any, item2: any) => {
    return (
      <Col span={24}>
        <Row justify="space-between">
          <Col md={12} xs={24}>
            {item1}
          </Col>
          <Col md={12} xs={24}>
            {item2}
          </Col>
        </Row>
      </Col>
    )
  }

  const FooterContent = isAdmin ? approveFooter : null

  const ConfirmRejectDailog = (
    <ConfirmQuestion
      title="ไม่อนุมัติ"
      placeholder="กรุณาระบุเหตุผลที่ไม่อนุมัติ"
      confirmBtnTxt="ไม่อนุมัติ"
      isShow={rejectDialog}
      handleOk={(remark: string) => onReject(remark)}
      handleCancel={() => setRejectDialog(false)}
      loading={event.IsLoading}
    />
  )

  const ConfirmApproveDailog = (
    <ConfirmDailog
      title="ยืนยันอนุมัติ"
      description="คุณแน่ใจที่จะอนุมัติ Agent QR นี้หรือไม่?"
      confirmBtnTxt="อนุมัติ"
      isShow={approveDialog}
      handleOk={onApprove}
      handleCancel={() => setApproveDialog(false)}
      loading={event.IsLoading}
    />
  )

  const RegisterInfo = (
    <BoxWrapper title="ข้อมูลผู้ใช้งาน">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(renderInfo('อีเมล', detail?.email), renderInfo('รหัสพนักงาน', detail?.employee_code))}
        {renderInfoRow(renderInfo('ชื่อ', detail?.first_name), renderInfo('นามสกุล', detail?.last_name))}
        {renderInfoRow(renderInfo('เบอร์โทรศัพท์', detail?.mobile), null)}
      </Row>
    </BoxWrapper>
  )

  const FinanceInfo = (
    <BoxWrapper title="ข้อมูลด้านการเงิน">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('ธนาคาร', getBankName(detail?.bank_name)),
          renderInfo('ชื่อบัญชี', detail?.bank_account_name),
        )}
        {renderInfoRow(
          renderInfo('เลขที่บัญชี', detail?.bank_account_no),
          renderInfo('เรทคอมมิชชั่น', `${detail?.commission || ''} %`),
        )}
      </Row>
    </BoxWrapper>
  )

  const getName = (key: string, val: any) => {
    const result =
      partnerCodes &&
      partnerCodes[key] &&
      partnerCodes[key].find((item: { [key: string]: any }) => {
        return Object.keys(item) == val
      })
    return result ? `${Object.keys(result)} - ${Object.values(result)}` : ''
  }

  const ParentInfo = (
    <BoxWrapper title="โครงสร้างสายงาน">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('หัวหน้างาน', detail?.parent_agent_code),
          renderInfo('ประเภทตัวแทน', getMenuValue(menuChaiyoUserAgentType, detail?.partner_type || '')),
        )}
        {renderInfoRow(
          renderInfo('Channel Code', getName('channel_code', detail?.channel_code || '')),
          renderInfo('Industry Code', getName('industry_code', detail?.industry_code)),
        )}
        {renderInfoRow(renderInfo('Level1-Company Code', `${detail?.company_code || ''}`), null)}
      </Row>
    </BoxWrapper>
  )

  const renderActiveStatus = (status: string) => {
    return (
      <Switch
        loading={event.IsLoading}
        checked={status === Status.ACTIVE}
        onChange={(val: boolean) => {
          onUpdate(val === true ? Status.ACTIVE : Status.INACTIVE)
        }}
        checkedChildren={<SwitchText>เปิดใช้งาน</SwitchText>}
        unCheckedChildren={<SwitchText>ปิดใช้งาน</SwitchText>}
      />
    )
  }

  const StatusInfo = (
    <BoxWrapper title="สถานะผู้ใช้งาน">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('สถานะการอนุมัติ', renderStatus(detail?.partner_status || '')),
          detail?.partner_status === AgentApproveStatus.REJECT
            ? renderInfo('เหตุผลที่ไม่อนุมัติ', detail?.reason)
            : '',
        )}
        {isApprove &&
          (isAdmin
            ? renderInfoRow(renderInfo('สถานะผู้ใช้', getStatus(detail?.status === Status.ACTIVE)), null)
            : renderInfoRow(renderInfo('สถานะผู้ใช้', renderActiveStatus(detail?.status || '')), null))}
      </Row>
    </BoxWrapper>
  )

  const title = (
    <Space align="start">
      <div>
        <Title>
          ข้อมูลตัวแทน : {detail?.first_name} {detail?.last_name}
        </Title>
        <CreatedDate>วันที่สมัคร {formatDateTime(detail?.created_at || '')}</CreatedDate>
      </div>
      {renderStatus(detail?.partner_status || '')}
    </Space>
  )

  return (
    <DetailDrawer
      title={title}
      onCloseDrawer={onCloseDrawer}
      visible={visible}
      footer={isPending && FooterContent}
      width="85%"
      maskClosable={true}
    >
      <LoadingView isShow={detailEvent.IsLoading} />
      <EventModal event={[event]} enable={true} successCalback={onEventSuccess} errorCalback={clearEvent} />
      <Row gutter={[16, 16]}>
        {isApprove && (
          <Col md={24} xs={24}>
            <BoxWrapper title={`รหัสตัวแทน : ${detail?.agent_code || ''}`}>
              <Center>
                <AgentQrCode
                  title={detail?.user?.uuid || ''}
                  uuid={detail?.user?.uuid || ''}
                  agentType={'agen-qr'}
                  width={200}
                  domain={detail?.user?.domain || ''}
                  userGroup={detail?.user?.user_group || ''}
                  agentCode={detail?.agent_code || ''}
                />
              </Center>
            </BoxWrapper>
          </Col>
        )}
        <Col md={24} xs={24}>
          {ParentInfo}
        </Col>
        <Col md={24} xs={24}>
          {RegisterInfo}
        </Col>
        <Col md={24} xs={24}>
          {FinanceInfo}
        </Col>
        <Col md={24} xs={24}>
          {StatusInfo}
        </Col>
      </Row>
      {ConfirmRejectDailog}
      {ConfirmApproveDailog}
    </DetailDrawer>
  )
}

const Title = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
`

const CreatedDate = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
`

const SwitchText = styled.div`
  width: 50px;
`

const Center = styled.div`
  width: max-content;
  margin: 0 auto;
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectActionEvent(state),
    detail: selectDetail(state),
    detailEvent: selectDetailEvent(state),
    loginData: selectLoginData(state),
    partnerCodes: selectAgentCode(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(AgentQrDetail)
