import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import {
  ActionRes,
  ListReq,
  ListRes,
  DetailReq,
  DetailRes,
  UpdateReq,
  CountReq,
  CountRes,
  AssignReq,
} from './types'

export const uploadFileAction = createAsyncAction(
  'CUSTOMER_FILE/UPLOAD_REQ',
  'CUSTOMER_FILE/UPLOAD_SUCCESS',
  'CUSTOMER_FILE/UPLOAD_FAILURE',
)<FormData, ActionRes, Error>()

export const getListAction = createAsyncAction(
  'CUSTOMER_FILE/GET_CUSTOMER_FILE_LIST_REQ',
  'CUSTOMER_FILE/GET_CUSTOMER_FILE_LIST_SUCCESS',
  'CUSTOMER_FILE/GET_CUSTOMER_FILE_LIST_FAILURE',
)<ListReq, ListRes, Error>()

export const getDetailAction = createAsyncAction(
  'CUSTOMER_FILE/GET_CUSTOMER_FILE_DETAIL_REQ',
  'CUSTOMER_FILE/GET_CUSTOMER_FILE_DETAIL_SUCCESS',
  'CUSTOMER_FILE/GET_CUSTOMER_FILE_DETAIL_FAILURE',
)<DetailReq, DetailRes, Error>()

export const assignCustomerAction = createAsyncAction(
  'CUSTOMER_FILE/UPDATE_ASSIGN_CUSTOMER_FILE_REQ',
  'CUSTOMER_FILE/UPDATE_ASSIGN_CUSTOMER_SUCCESS',
  'CUSTOMER_FILE/UPDATE_ASSIGN_CUSTOMER_FAILURE',
)<AssignReq, ActionRes, Error>()

export const updateAction = createAsyncAction(
  'CUSTOMER_FILE/UPDATE_CUSTOMER_FILE_REQ',
  'CUSTOMER_FILE/UPDATE_CUSTOMER_FILE_SUCCESS',
  'CUSTOMER_FILE/UPDATE_CUSTOMER_FILE_FAILURE',
)<UpdateReq, ActionRes, Error>()

export const countCustomerFileTabAction = createAsyncAction(
  'CUSTOMER_FILE/COUNT_CUSTOMER_FILE_TAB_REQ',
  'CUSTOMER_FILE/COUNT_CUSTOMER_FILE_TAB_SUCCESS',
  'CUSTOMER_FILE/COUNT_CUSTOMER_FILE_TAB_FAILURE',
)<CountReq, CountRes, Error>()

export const clearEventAction = createStandardAction('CUSTOMER_FILE/CLEAR_EVENT')<void>()
