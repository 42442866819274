import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'
import { initEvent, initMeta } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'
import { convertMetaData } from 'Store/common/common.function'

const initialState: State = {
  customerInfo: {
    event: cloneDeep(initEvent),
    data: undefined,
  },
  customerList: {
    data: {
      data: [],
      meta: cloneDeep(initMeta),
    },
    event: cloneDeep(initEvent),
  },
  actionEvent: cloneDeep(initEvent),
  customerDetail: {
    detail: undefined,
    event: cloneDeep(initEvent),
  },
  lastCreateTask: undefined,
  countData: {
    count_me: 0,
    count_all: 0,
  },
  taskEvent: cloneDeep(initEvent),
  isRefresh: false,
}

export default function (state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.getListAction.request):
        draft.customerList.event = cloneDeep(initEvent)
        draft.customerList.event.IsLoading = true
        draft.customerList.data = {
          data: [],
          meta: cloneDeep(initMeta),
        }
        return draft
      case getType(actions.getListAction.success):
        response = action.payload
        draft.customerList.event.IsLoading = false
        draft.customerList.event.SubmitSuccess = true
        draft.customerList.data.data = response.data
        if (response.meta) {
          draft.customerList.data.meta = convertMetaData(response.meta)
        }
        return draft
      case getType(actions.getListAction.failure):
        draft.customerList.event.IsLoading = false
        draft.customerList.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getDetailAction.request):
        draft.customerDetail.event = cloneDeep(initEvent)
        draft.customerDetail.event.IsLoading = true
        draft.customerDetail.detail = undefined
        return draft
      case getType(actions.getDetailAction.success):
        response = action.payload
        draft.customerDetail.event.IsLoading = false
        draft.customerDetail.event.SubmitSuccess = true
        draft.customerDetail.detail = response.data
        return draft
      case getType(actions.getDetailAction.failure):
        draft.customerDetail.event.IsLoading = false
        draft.customerDetail.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.assignCustomerAction.request):
      case getType(actions.updateAction.request):
        draft.taskEvent = cloneDeep(initEvent)
        draft.taskEvent.IsLoading = true
        return draft
      case getType(actions.updateAction.success):
        response = action.payload
        if (response && response.status.code === 200) {
          draft.taskEvent.IsLoading = false
          draft.taskEvent.SubmitError = undefined
          draft.taskEvent.SubmitSuccess = true
          draft.taskEvent.MessageSuccess = 'บันทึกข้อมูลสำเร็จ'
        } else {
          draft.taskEvent.IsLoading = false
          draft.taskEvent.SubmitError = response.message
        }
        return draft
      case getType(actions.assignCustomerAction.success):
        response = action.payload
        if (response && response.status.code === 200) {
          draft.taskEvent.IsLoading = false
          draft.taskEvent.SubmitError = undefined
          draft.taskEvent.SubmitSuccess = true
          draft.taskEvent.MessageSuccess = 'รับงานสำเร็จ โปรดตรวจสอบในเมนูมอบหมายให้ฉัน'
        } else {
          draft.taskEvent.IsLoading = false
          draft.taskEvent.SubmitError = response.message
          if (response.message === 'มีคนรับงานนี้ไปแล้ว') {
            draft.isRefresh = true
          }
        }
        return draft
      case getType(actions.updateAction.failure):
        draft.taskEvent.IsLoading = false
        draft.taskEvent.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.assignCustomerAction.failure):
        console.log('assignCustomerAction fail')
        draft.taskEvent.IsLoading = false
        draft.taskEvent.SubmitError = parseErrorMessage(action.payload)
        draft.isRefresh = true
        return draft

      case getType(actions.countCustomerFileTabAction.request):
        return draft
      case getType(actions.countCustomerFileTabAction.success):
        draft.countData = action.payload.data
        return draft
      case getType(actions.countCustomerFileTabAction.failure):
        return draft

      case getType(actions.uploadFileAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        return draft
      case getType(actions.uploadFileAction.success):
        response = action.payload
        if (response && response.status.code === 200) {
          draft.actionEvent.IsLoading = false
          draft.actionEvent.SubmitError = undefined
          draft.actionEvent.SubmitSuccess = true
          draft.actionEvent.MessageSuccess =
            'ระบบกำลังนำข้อมูลจากไฟล์เข้าตามลำดับ โปรดรอสักครู่... แล้วทำการรีเฟรซหน้าจอเพื่อดูข้อมูลใหม่อีกครั้ง'
        } else {
          draft.actionEvent.IsLoading = false
          draft.actionEvent.SubmitError = response.message
        }
        return draft
      case getType(actions.uploadFileAction.failure):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.clearEventAction):
        draft.customerInfo.event = cloneDeep(initEvent)
        draft.actionEvent = cloneDeep(initEvent)
        draft.taskEvent = cloneDeep(initEvent)
        draft.isRefresh = false
        return draft

      default:
        return state
    }
  })
}
