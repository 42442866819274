import React from 'react'
import { PartnerStructureData } from 'Store/partner-qr/types'
import FormItem from './FormItem'
import LabelItem from './LabelItem'
import PartnerStructer from './PartnerStructer'

type Props = {
  name: string
  categories: PartnerStructureData[]
  label?: string
  onChange: (val: any, name: string) => void
  onOpenEditCategory?: () => void
  isError?: boolean
  value: any
  isRequired?: boolean
  canAddCategory?: boolean
  readonly?: boolean
  hasLastchild?: boolean
  changeOnSelect?: boolean
}

const AppPartnerStructer = (props: Props) => {
  const {
    label = '',
    name,
    isError = false,
    value,
    isRequired = false,
    canAddCategory = false,
    readonly = false,
    onOpenEditCategory = () => {},
    onChange,
    categories,
    hasLastchild,
    changeOnSelect = true,
  } = props

  return (
    <FormItem label="" status={isRequired && isError ? 'error' : ''} error={`กรุณาระบุ ${label}`}>
      {label && (
        <>
          <LabelItem label={isRequired ? `${label}*` : label} /> <br />
        </>
      )}
      <PartnerStructer
        data={value}
        categories={categories}
        handleChange={(val: string) => onChange(val, name)}
        onOpenEditCategory={onOpenEditCategory}
        readonly={readonly}
        canAddCategory={canAddCategory}
        hasLastchild={hasLastchild}
        changeOnSelect={changeOnSelect}
        placeholder="เลือกหัวหน้างาน"
      />
    </FormItem>
  )
}

export default AppPartnerStructer
