import { isToday } from 'helpers/format'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { ReciveMessageSocket, SelfeMessageSocket, User, UserSocket, Message } from './types'

const isObject = (data: any) => {
  return typeof data === 'object'
}

export const mapUser = (userList: User[], newUser: UserSocket): User[] => {
  let newUserList = cloneDeep(userList) as User[]
  const hasUser = newUserList.find(item => item.userID === newUser.userID)
  if (hasUser) {
    newUserList = conectedUser(userList, newUser)
  } else {
    const lastID = Math.round(Date.now() + Math.random())
    newUserList.push({
      id: lastID + 1,
      name: newUser.username,
      userID: newUser.userID,
      isGroup: false,
      status: 'online',
      roomType: 'contact',
      profilePicture: newUser.userImage,
      messages: [],
      userSocket: newUser,
      hasHistory: false,
    })
  }

  return newUserList
}

export const disconectedUser = (userList: User[], newUser: UserSocket): User[] => {
  let newUserList = cloneDeep(userList) as User[]
  newUserList = newUserList.map(item => {
    if (item.userID === newUser.userID) {
      item.status = 'offline'
    }
    return item
  })

  return newUserList
}

export const conectedUser = (userList: User[], newUser: UserSocket): User[] => {
  let newUserList = cloneDeep(userList) as User[]
  newUserList = newUserList.map(item => {
    if (item.userID === newUser.userID) {
      item.status = 'online'
      item.userSocket = newUser
    }
    return item
  })

  return newUserList
}

export const recieveMessage = (
  userList: User[],
  message: ReciveMessageSocket,
  active_user: number,
  uuid: string,
): User[] => {
  let newUserList = cloneDeep(userList) as User[]
  newUserList = newUserList.map(item => {
    if (item.userID === message.fromUser.userID) {
      item.messages?.push({
        ...formatMessage(message),
        userName: message.fromUser.username,
        userType: 'receiver',
        time: moment().format(),
      })

      if (item.id !== active_user && (item.userSocket.assignID === '' || item.userSocket.assignID === uuid)) {
        if (item.unRead && item.unRead > 0) {
          item.unRead = item.unRead + 1
        } else {
          item.unRead = 1
        }
      }
    }
    return item
  })

  return newUserList
}

export const selfMessage = (userList: User[], message: SelfeMessageSocket): User[] => {
  let newUserList = cloneDeep(userList) as User[]
  newUserList = newUserList.map(item => {
    if (item.userID === message.toUser.userID) {
      item.messages?.push({
        ...formatMessage(message),
        userName: 'Me',
        userType: 'sender',
        time: moment().format(),
      })
    }
    return item
  })

  return newUserList
}

const formatMessage = (message: ReciveMessageSocket | SelfeMessageSocket) => {
  return {
    message:
      message.content.cType === 'text'
        ? isObject(message.content.cBody)
          ? message.content.cBody.name
          : message.content.cBody
        : '',
    time: message?.updatedAt || '',
    isImageMessage: message.content.cType === 'image',
    isFileMessage: message.content.cType === 'file',
    isLinkMessage: message.content.cType === 'link',
    isAutoMessage: message.content.cType === 'auto',
    imageMessage:
      message.content.cType === 'image'
        ? [{ image: message.content.cBody }]
        : message.content.cType === 'link'
        ? [{ image: message.content.cBody?.image }]
        : message.content.cType === 'auto'
        ? [{ image: message.content.cBody?.image }]
        : [],
    fileMessage: message.content.cType === 'file' ? message.content.cBody : '',
    linkMessage: message.content.cType === 'link' ? message.content.cBody?.link : '',
    autoMessage: message.content.cType === 'auto' ? message.content.cBody?.question : '',
  }
}

const formatHisttoryMessage = (userType: string, messageList: ReciveMessageSocket[]): Message[] => {
  const msg = [] as Message[]
  let todayFag = false
  messageList &&
    messageList.forEach(message => {
      const isSender = message.fromUser.userType === userType
      const today = isToday(message?.updatedAt)
      //const time = today ? formatTimeTh(message?.updatedAt || '') : formatDateTimeTh(message?.updatedAt || '')
      if (today && !todayFag) {
        todayFag = true
        msg.push({
          isToday: true,
        })
      }
      msg.push({
        ...formatMessage(message),
        userName: isSender ? 'Me' : message.fromUser.username,
        userType: isSender ? 'sender' : 'receiver',
        time: message?.updatedAt || '',
      })
    })
  return msg
}

export const consumerHistory = (messageList: ReciveMessageSocket[]): Message[] => {
  return formatHisttoryMessage('consumer', messageList)
}

export const agentHistoryMessage = (
  activeUser: number,
  userList: User[],
  messageList: ReciveMessageSocket[],
): User[] => {
  //const activeUserInfo = findUserById(userList, active_user)
  let newUserList = [] as User[]
  newUserList = userList.map(item => {
    if (item.id === activeUser) {
      item.messages = formatHisttoryMessage('agent', messageList)
      if (messageList && messageList.length > 0) {
        item.hasHistory = true
      }
    }
    return item
  })
  return newUserList
}

export const typingMessage = (userList: User[], user: User): User[] => {
  let newUserList = cloneDeep(userList) as User[]
  newUserList = newUserList.map(item => {
    if (item.userID === user.userID) {
      item.isTyping = true
      item.messages?.push({
        id: 999999,
        message: '',
        userType: 'receiver',
        isTyping: true,
      })
    }
    return item
  })

  return newUserList
}

export const typedMessage = (userList: User[], user: User): User[] => {
  let newUserList = cloneDeep(userList) as User[]
  newUserList = newUserList.map(item => {
    if (item.userID === user.userID) {
      item.isTyping = false
      const msgList = item.messages?.filter(msg => msg.id !== 999999)
      item.messages = msgList
    }
    return item
  })

  return newUserList
}

export const getAssignUser = (userList: User[], user: User): User[] => {
  let assingUser = [] as User[]
  assingUser = userList.filter(item => item.userSocket.assignID === user.userID)

  return assingUser
}

export const getUnAssignUser = (userList: User[], user: User): User[] => {
  let assingUser = [] as User[]
  assingUser = userList.filter(item => item.userSocket.assignID === '')

  return assingUser
}

export const findUserById = (userList: User[], id: number): User | undefined => {
  const user = userList.find(item => item.id === id)
  return user
}

export const readAllMessage = (userList: User[], activeUser: number): User[] => {
  let newUserList = cloneDeep(userList) as User[]
  newUserList = newUserList.map(item => {
    if (item.id === activeUser) {
      item.unRead = 0
    }
    return item
  })

  return newUserList
}

export const countUnreadMessage = (userList: User[]): number => {
  let unread = 0
  userList.forEach(item => {
    if (item.unRead) {
      unread += item.unRead
    }
  })

  return unread
}

export const updateFriendName = (userList: User[], name: string, uuid: string): User[] => {
  let newUserList = cloneDeep(userList) as User[]
  newUserList = newUserList.map(item => {
    if (item.userID === uuid) {
      item.name = name
    }
    return item
  })

  return newUserList
}

export const findOldMessage = (userList: User[], uuid: string): Message[] => {
  const user = userList && userList.find(item => item.userID === uuid)
  return user ? user?.messages || [] : []
}

export const updateBookedFriend = (
  userList: User[],
  user: UserSocket,
  isSelfBook: boolean,
  isBookAction: boolean,
): User[] => {
  let newUserList = cloneDeep(userList) as User[]
  if (isBookAction) {
    if (isSelfBook) {
      newUserList = newUserList.map(item => {
        if (item.userID === user.userID) {
          item.userSocket.assignID = user.assignID
        }
        return item
      })
    } else {
      newUserList = newUserList.filter(item => item.userSocket.userID !== user.userID)
    }
  } else {
    const findUser = newUserList.find(item => item.userID === user.userID)
    if (findUser) {
      newUserList = newUserList.map(item => {
        if (item.userID === user.userID) {
          item.userSocket.assignID = user.assignID
        }
        return item
      })
    } else {
      const lastID = Math.round(Date.now() + Math.random())
      newUserList.push(covertFrindData(lastID, user, userList))
    }
  }

  return newUserList
}

export const covertFrindData = (index: number, element: UserSocket, userList: User[]) => {
  return {
    id: index + 1,
    name: element.username,
    isGroup: false,
    roomType: 'contact',
    status: element.socketID == '' || element.socketID == 'dummy' ? 'offline' : 'online',
    userID: element.userID,
    profilePicture: element.userImage,
    messages: findOldMessage(userList, element.userID),
    userSocket: element,
    hasHistory: false,
  } as User
}

export const updateAgentQr = (userList: User[], user: UserSocket): User[] => {
  let newUserList = cloneDeep(userList) as User[]
  newUserList = newUserList.map(item => {
    if (item.userID === user.userID) {
      item.userSocket = user
    }
    return item
  })

  return newUserList
}
