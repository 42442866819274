import { CreateMapping } from 'Store/common/common.types'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import {
  ListReq,
  ListRes,
  DetailReq,
  DetailRes,
  ActionRes,
  UpdateReq,
  UpdateStatusReq,
  UpdateQrPartnerImageReq,
  CreateReq,
  PartnerStructureReq,
  PartnerStructureRes,
  PartnerCodeReq,
  PartnerCodeRes,
  PartnerInfoReq,
  PartnerInfoRes,
  ProfileReq,
  ProfileRes,
} from './types'

export const createAction = createAsyncAction(
  'QRPARTNER/CREATE_QRPARTNER_REQ',
  'QRPARTNER/CREATE_QRPARTNER_SUCCESS',
  'QRPARTNER/CREATE_QRPARTNER_FAILURE',
)<CreateReq, ActionRes, Error>()

export const getListAction = createAsyncAction(
  'QRPARTNER/GET_QRPARTNER_LIST_REQ',
  'QRPARTNER/GET_QRPARTNER_LIST_SUCCESS',
  'QRPARTNER/GET_QRPARTNER_LIST_FAILURE',
)<ListReq, ListRes, Error>()

export const getDetailAction = createAsyncAction(
  'QRPARTNER/GET_QRPARTNER_DETAIL_REQ',
  'QRPARTNER/GET_QRPARTNER_DETAIL_SUCCESS',
  'QRPARTNER/GET_QRPARTNER_DETAIL_FAILURE',
)<DetailReq, DetailRes, Error>()

export const updateAction = createAsyncAction(
  'QRPARTNER/UPDATE_QRPARTNER_REQ',
  'QRPARTNER/UPDATE_QRPARTNER_SUCCESS',
  'QRPARTNER/UPDATE_QRPARTNER_FAILURE',
)<UpdateReq, ActionRes, Error>()

export const updateQrPartnerStatusAction = createAsyncAction(
  'QRPARTNER/UPDATE_QRPARTNER_STATUS_REQ',
  'QRPARTNER/UPDATE_QRPARTNER_STATUS_SUCCESS',
  'QRPARTNER/UPDATE_QRPARTNER_STATUS_FAILURE',
)<UpdateStatusReq, ActionRes, Error>()

export const updateQrPartnerImageAction = createAsyncAction(
  'QRPARTNER/UPDATE_QRPARTNER_IMAGE_REQ',
  'QRPARTNER/UPDATE_QRPARTNER_IMAGE_SUCCESS',
  'QRPARTNER/UPDATE_QRPARTNER_IMAGE_FAILURE',
)<UpdateQrPartnerImageReq, ActionRes, Error>()

export const getPartnerStructerAction = createAsyncAction(
  'QRPARTNER/GET_QRPARTNER_STRUCTER_REQ',
  'QRPARTNER/GET_QRPARTNER_STRUCTER_SUCCESS',
  'QRPARTNER/GET_QRPARTNER_STRUCTER_FAILURE',
)<PartnerStructureReq, PartnerStructureRes, Error>()

export const getPartnerCodeAction = createAsyncAction(
  'QRPARTNER/GET_QRPARTNER_CODES_REQ',
  'QRPARTNER/GET_QRPARTNER_CODES_SUCCESS',
  'QRPARTNER/GET_QRPARTNER_SCODES_FAILURE',
)<PartnerCodeReq, PartnerCodeRes, Error>()

export const getPartnerInfoAction = createAsyncAction(
  'QRPARTNER/GET_QRPARTNER_INFO_REQ',
  'QRPARTNER/GET_QRPARTNER_INFO_SUCCESS',
  'QRPARTNER/GET_QRPARTNER_INFO_FAILURE',
)<PartnerInfoReq, PartnerInfoRes, Error>()

export const getPartnerProfileAction = createAsyncAction(
  'QRPARTNER/GET_QRPARTNER_PROFILE_REQ',
  'QRPARTNER/GET_QRPARTNER_PROFILE_SUCCESS',
  'QRPARTNER/GET_QRPARTNER_PROFILE_FAILURE',
)<ProfileReq, ProfileRes, Error>()

export const clearEventAction = createStandardAction('QRPARTNER/CLEAR_EVENT')<void>()
