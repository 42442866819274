import { OptionProps } from 'Components/form/InputAutocomplete'
import { escapeRegExp } from 'lodash'
import moment from 'moment'
import 'moment/locale/th'
import { AddressData } from 'Store/register/types'
import { BranchData, ReasonData } from 'Store/sale-task/types'
import {
  StatusText,
  monthNames,
  AgentStatusText,
  AgentStatus,
  AgentTypeText,
  AgentType,
  TaskType,
  TaskTypeText,
  CustomerStatus,
  CustomerStatusText,
  SaleTaskStatus,
  SaleTaskStatusText,
  menuBankAccount,
  Status,
} from './constanst'

export const calendarFormat = 'll'

export const toDateQuery = (date: any, format = 'YYYY-MM-DD') =>
  moment.isMoment(date) ? date.format(format) : moment(date || '').format(format)

export const toYearQuery = (date: any) =>
  moment.isMoment(date) ? date.format('YYYY') : moment().format('YYYY')

export const toDateMoment = (val: any): any => {
  if (typeof val === 'string') {
    return val === '' ? undefined : moment(val, 'YYYY-MM-DD')
  }

  return val
}

export const formatDateTime = (date: string): any => {
  if (!date) return ''
  moment.locale('th')
  return moment(date)
    .add(543, 'year')
    .format('lll')
}

export const formatDateWithoutTime = (date: string): any => {
  if (!date) return ''
  moment.locale('th')
  return moment(date)
    .add(543, 'year')
    .format('ll')
}

export const formatDate = (date: string): any => {
  if (!date) return ''
  moment.locale('th')
  return moment(date).format('DD/MM/YYYY')
}

export const currentDateTime = (): any => {
  moment.locale('th')
  return moment().format('DD-MM-YYYY HH:mm')
}

export const currentDateTimeThaiformat = (): any => {
  moment.locale('th')
  return moment()
    .add(543, 'year')
    .format('ll')
}

export const currentDate = (): any => {
  moment.locale('th')
  return moment().format('YYYY-MM-DD')
}

export const currentDateTimeTh = (): any => {
  moment.locale('th')
  return moment()
    .add(543, 'year')
    .format('HH:mm')
}

export const formatTimeTh = (date: string): any => {
  if (!date) return ''
  moment.locale('th')
  return moment(date)
    .add(543, 'year')
    .format('HH:mm')
}

export const formatDateTimeTh = (date: string): any => {
  if (!date) return ''
  moment.locale('th')
  return moment(date)
    .add(543, 'year')
    .format('lll')
}

export const lastDate = (day: number): any => {
  moment.locale('th')
  return moment()
    .subtract(day, 'days')
    .add(543, 'year')
    .format('ll')
}

export const isToday = (date: any) => {
  const today = new Date()
  const someDate = new Date(date)
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  )
}

export const formatTimeAgo = (date: string): any => {
  moment.locale('th')
  const fromDate = moment(date)
  return fromDate.fromNow(false)
}

export const formatYearold = (date: string): any => {
  moment.locale('th')
  const fromDate = moment(date)
  return Math.abs(fromDate.diff(moment(), 'years'))
}

export const getLast1Month = (): any => {
  const last = moment()
    .endOf('day')
    .subtract(1, 'months')
  return last
}

export const renderTime = () => {
  const values = []

  for (let index = 0; index < 24; index++) {
    const name = index <= 9 ? '0' + index : index
    values.push(`${name}:00`)
  }

  return values
}

export const renderMonth = () => {
  const values = []
  for (let index = 1; index <= 12; index++) {
    const name = index <= 9 ? '0' + index : index
    values.push({
      name: monthNames.en[index - 1],
      value: name,
    })
  }

  return values
}

export const formatBirthdate = (date: string): any => {
  if (!date) return ''
  moment.locale('th')
  return moment(date)
    .add(543, 'year')
    .format('ll')
}

export const uniqeID = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

export const formatCreditCard = (no: string): any => {
  //:TODO
  return no
}

export const formatAddress = (
  address: string = '',
  district: string = '',
  amphor: string = '',
  province: string = '',
  postcode: string = '',
): string => {
  if (!address && !district && !amphor && !province && !postcode) return ''
  const amphorTitle = amphor.search('เขต') === 0 ? '' : 'เขต/อำเภอ'
  const districtTitle = 'แขวง/ตำบล'
  return `${address || '-'} ${districtTitle} ${district || '-'} ${amphorTitle} ${amphor ||
    '-'} จังหวัด ${province || '-'} รหัสไปรษณีย์ ${postcode || '-'}`
}

export const formatAddressNoTitle = (
  address: string,
  district: string,
  amphor: string,
  province: string,
  postcode: string,
): string => {
  if (!address && !district && !amphor && !province && !postcode) return ''
  return ` ${address || '-'} ${district || '-'} ${amphor || '-'} ${province || '-'} ${postcode || '-'}`
}

export const formatSex = (sex: string): any => {
  if (!sex) return ''
  return sex === 'male' ? 'ชาย' : 'หญิง'
}

export const formatBusinessType = (type: string): any => {
  if (!type) return ''
  return type === 'individual' ? 'บุคคลธรรมดา' : 'นิติบุคคล'
}

export const numberWithComma = (number: number = 0, isNumFormat: boolean = false): string => {
  if (isNaN(number) || number === null) {
    return '0.00'
  }
  if (isNumFormat) {
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }
  return number.toLocaleString()
}

export const percentCalulator = (val?: number, total?: number): number => {
  return ((val || 0) * 100) / (total || 0)
}

export const mergeArray = (datas: any[]) => {
  let result = [] as any[]
  if (datas) {
    datas.forEach(item => {
      result = result.concat(item)
    })
  }

  return result
}

export const roundNumber = (number: number = 0): number => {
  return Math.trunc((number + Number.EPSILON) * 100) / 100
}

export const toPercent = (number: number = 0) => {
  return number * 100
}

export const validateEmail = (mail: string) => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
    return true
  }
  return false
}

export const formatUnitWaranty = (value: string): string => {
  switch (value) {
    case 'days':
      return 'วัน'
    case 'months':
      return 'เดือน'
    case 'year':
      return 'ปี'
    default:
      return value
  }
}

export const replaceAll = (str: string, find: string, replace: string): string => {
  return str.replace(new RegExp(escapeRegExp(',' + find + ','), 'g'), ',' + replace + ',')
}

export const getRequestString = (params: any) => {
  let string = ''
  for (const key in params) {
    string += string !== '' ? '&' : string
    string += `${key}=${params[key] ? params[key] : ''}`
  }
  return string
}

export const getStatus = (val: boolean) => {
  return val ? StatusText.ACTIVE : StatusText.INACTIVE
}

export const isFormRequired = (requireFeild: string[], name: string) => {
  if (requireFeild.includes(name)) return true
  return false
}

export const isFormError = (formError: string[], name: string) => {
  if (formError.includes(name)) return true
  return false
}

export const isFilter = (filter: object) => {
  for (const prop in filter) {
    if (filter[prop]) return true
  }
  return false
}

export const isFormDisabled = (requireFeild: string[], name: string) => {
  if (requireFeild.includes(name)) return true
  return false
}
export const formValidation = (requireFeild: string[], formData: any, allow0: boolean = false) => {
  const error = [] as string[]
  requireFeild.forEach((name: string) => {
    if (!allow0) {
      if (!formData[name] || formData[name] <= 0) {
        error.push(name)
      }
    }

    if (allow0) {
      if ((!formData[name] && formData[name] != 0) || formData[name] === '') {
        error.push(name)
      }
    }
  })
  return error
}

export const firstUpperCase = (text: any) => {
  if (typeof text !== 'string') return ''
  return text.charAt(0).toUpperCase()
}

export const getMenuSelect = (data: any[], nameKey: string = 'name', valueKey: string = 'id'): any[] => {
  const menu = [] as any[]
  data.forEach((item: any) => {
    menu.push({
      name: item[nameKey] || '',
      value: item[valueKey] || '',
    })
  })
  return menu
}

export const getBranchMenuSelect = (data: BranchData[]): any[] => {
  const menu = [] as any[]
  data &&
    data.forEach((item: BranchData) => {
      menu.push({
        name: `${item.id} - ${item.name}`,
        value: item.name,
      })
    })
  return menu
}

export const getReasonhMenuSelect = (data: ReasonData[]): any[] => {
  const menu = [] as any[]
  data &&
    data.forEach((item: ReasonData) => {
      menu.push({
        name: `${item.status} - ${item.text}`,
        value: `${item.status} - ${item.text}`,
      })
    })
  return menu
}

// export const getAgentStatus = () => {
//   let text = AgentStatusText.WAITING
//   text = AgentStatusText.REJECT
//   return text
// }

export const getAgentStatus = (status: string) => {
  let text = ''
  let color = ''
  switch (status) {
    case AgentStatus.APPROVE:
      text = AgentStatusText.APPROVE
      color = 'green'
      break
    case AgentStatus.REJECT:
      text = AgentStatusText.REJECT
      color = 'red'
      break
    case AgentStatus.PENDING:
      text = AgentStatusText.PENDING
      color = 'orange'
      break
    default:
      text = status
      break
  }
  return {
    text,
    color,
  }
}

export const getCustomerTaskStatus = (status: string) => {
  let text = ''
  let color = ''
  switch (status) {
    case CustomerStatus.OPEN:
      text = CustomerStatusText.OPEN
      color = 'blue'
      break
    case CustomerStatus.CANCEL:
      text = CustomerStatusText.CANCEL
      color = 'red'
      break
    case CustomerStatus.CLOSE:
      text = CustomerStatusText.CLOSE
      color = 'green'
      break
    case CustomerStatus.SENDCASE:
      text = CustomerStatusText.SENDCASE
      color = 'green'
      break
    default:
      text = status
      break
  }
  return {
    text,
    color,
  }
}

export const getSaleTaskStatus = (status: string) => {
  let text = ''
  let color = ''
  switch (status) {
    case SaleTaskStatus.INQUE:
      text = SaleTaskStatusText.INQUE
      color = 'blue'
      break
    case SaleTaskStatus.ONPROCESS:
      text = SaleTaskStatusText.ONPROCESS
      color = 'gold'
      break
    case SaleTaskStatus.TOBRANCH:
      text = SaleTaskStatusText.TOBRANCH
      color = 'cyan'
      break
    case SaleTaskStatus.REJECTED:
      text = SaleTaskStatusText.REJECTED
      color = 'red'
      break
    case SaleTaskStatus.CLOSED:
      text = SaleTaskStatusText.CLOSED
      color = 'green'
      break
    case SaleTaskStatus.CANCEL:
      text = SaleTaskStatusText.CANCEL
      color = 'red'
      break
    default:
      text = status
      break
  }
  return {
    text,
    color,
  }
}

export const getTaskLogsStatus = (status: string) => {
  let text = ''
  let color = ''
  switch (status) {
    case CustomerStatus.OPEN:
      text = CustomerStatusText.OPEN
      color = 'green'
      break
    case CustomerStatus.CANCEL:
      text = CustomerStatusText.CANCEL
      color = 'red'
      break
    case CustomerStatus.CLOSE:
      text = CustomerStatusText.CLOSE
      color = 'green'
      break
    case CustomerStatus.SENDCASE:
      text = CustomerStatusText.SENDCASE
      color = 'green'
      break
    case 'edit':
      text = 'อัปเดตข้อมูล'
      color = 'green'
      break
    case SaleTaskStatus.INQUE:
      text = SaleTaskStatusText.INQUE
      color = 'green'
      break
    case SaleTaskStatus.ONPROCESS:
      text = SaleTaskStatusText.ONPROCESS
      color = 'green'
      break
    case SaleTaskStatus.TOBRANCH:
      text = SaleTaskStatusText.TOBRANCH
      color = 'green'
      break
    case SaleTaskStatus.CANCEL:
      text = SaleTaskStatusText.CANCEL
      color = 'red'
      break
    case 'assign':
      text = 'รับงาน'
      color = 'green'
      break
    default:
      text = status
      break
  }
  return {
    text,
    color,
  }
}

export const validatePasswordFormat = (password: string) => {
  const validKey = []
  //const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
  const uppercase = new RegExp('^(?=.*[A-Z])')
  const lowercase = new RegExp('^(?=.*[a-z])')
  const number = new RegExp('^(?=.*[0-9])')
  const sign = new RegExp('^(?=.*[!@#$%^&*])')
  const length = new RegExp('^(?=.{8,})')
  if (uppercase.test(password)) {
    validKey.push('uppercase')
  }

  if (lowercase.test(password)) {
    validKey.push('lowercase')
  }

  if (number.test(password)) {
    validKey.push('number')
  }

  if (sign.test(password)) {
    validKey.push('sign')
  }

  if (length.test(password)) {
    validKey.push('length')
  }
  return validKey
}

export const getAddressFormSubdistrictID = (data: AddressData[], subDistrictID: number) => {
  return data && data.find(item => item.sub_district.id === subDistrictID)
}

export const formatAgentype = (type: string): any => {
  if (!type) return ''
  return type === AgentType.COPORATE ? AgentTypeText.COPORATE : AgentTypeText.INDIVIDUAL
}

export const formatTaskype = (type: string): any => {
  if (type === TaskType.INSURANCE) {
    return TaskTypeText.INSURANCE
  }
  return type
}

export const replaceChatText = (orgString: string) => {
  const text = preventCrossSiteScripting(orgString)
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text
    .replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>'
    })
    .replace(/\r?\n/g, '</br>')
}

export const getGeetingTabName = (key: string): any => {
  switch (key) {
    case 'geeting':
      return 'Geeting Message'
    case 'transfer':
      return 'โอนงาน'
    case 'end':
      return 'จบการสนทนา'
    case 'template':
      return 'เทมเพลตข้อความ'
    default:
      return key
  }
}

export const getBankName = (type?: string) => {
  let name = ''
  menuBankAccount.forEach(item => {
    if (item.value === type) {
      name = item.name
      return
    }
  })
  return name
}

export const getMenuValue = (menu: { value: string; name: string }[], val: string): any => {
  const result = menu && menu.find((item: any) => item?.value === val)
  return (result && result?.name) || ''
}

export const preventCrossSiteScripting = (rawStr: string): string => {
  let result = ''
  result = rawStr.replace(/[\u00A0-\u9999<>\&]/g, function(i) {
    return '&#' + i.charCodeAt(0) + ';'
  })
  return result
}
