import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import {
  ActionRes,
  ListReq,
  ListRes,
  DetailReq,
  DetailRes,
  UpdateReq,
  UpdateStatusReq,
  CountReq,
  CountRes,
  BranchReq,
  BranchRes,
  ReasonReq,
  ReasonRes,
  AssignReq,
} from './types'

export const getListAction = createAsyncAction(
  'SALE_TASK/GET_SALE_TASK_LIST_REQ',
  'SALE_TASK/GET_SALE_TASK_LIST_SUCCESS',
  'SALE_TASK/GET_SALE_TASK_LIST_FAILURE',
)<ListReq, ListRes, Error>()

export const getDetailAction = createAsyncAction(
  'SALE_TASK/GET_SALE_TASK_DETAIL_REQ',
  'SALE_TASK/GET_SALE_TASK_DETAIL_SUCCESS',
  'SALE_TASK/GET_SALE_TASK_DETAIL_FAILURE',
)<DetailReq, DetailRes, Error>()

export const updateAction = createAsyncAction(
  'SALE_TASK/UPDATE_SALE_TASK_REQ',
  'SALE_TASK/UPDATE_SALE_TASK_SUCCESS',
  'SALE_TASK/UPDATE_SALE_TASK_FAILURE',
)<UpdateReq, ActionRes, Error>()

export const updateCustomerStatusAction = createAsyncAction(
  'SALE_TASK/UPDATE_SALE_TASK_STATUS_REQ',
  'SALE_TASK/UPDATE_SALE_TASK_STATUS_SUCCESS',
  'SALE_TASK/UPDATE_SALE_TASK_STATUS_FAILURE',
)<UpdateStatusReq, ActionRes, Error>()

export const assignTaskAction = createAsyncAction(
  'SALE_TASK/ASSIGN_SALE_TASK_REQ',
  'SALE_TASK/ASSIGN_SALE_TASK_SUCCESS',
  'SALE_TASK/ASSIGN_SALE_TASK_FAILURE',
)<AssignReq, ActionRes, Error>()

export const countAllTabAction = createAsyncAction(
  'SALE_TASK/COUNT_ALL_TASK_TAB_REQ',
  'SALE_TASK/COUNT_ALL_TASK_TAB_SUCCESS',
  'SALE_TASK/COUNT_ALL_TASK_TAB_FAILURE',
)<CountReq, CountRes, Error>()

export const countMeTabAction = createAsyncAction(
  'SALE_TASK/COUNT_ME_TASK_TAB_REQ',
  'SALE_TASK/COUNT_ME_TASK_TAB_SUCCESS',
  'SALE_TASK/COUNT_ME_TASK_TAB_FAILURE',
)<CountReq, CountRes, Error>()

export const getBranchListAction = createAsyncAction(
  'SALE_TASK/GET_BRANCH_LIST_REQ',
  'SALE_TASK/GET_BRANCH_LIST_SUCCESS',
  'SALE_TASK/GET_BRANCH_LIST_FAILURE',
)<BranchReq, BranchRes, Error>()

export const getReasonAction = createAsyncAction(
  'SALE_TASK/GET_REASON_LIST_REQ',
  'SALE_TASK/GET_REASON_LIST_SUCCESS',
  'SALE_TASK/GET_REASON_LIST_FAILURE',
)<ReasonReq, ReasonRes, Error>()

export const clearEventAction = createStandardAction('SALE_TASK/CLEAR_EVENT')<void>()
