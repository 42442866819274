import produce from 'immer'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action, User, UserSocket } from './types'

//Import Images
import avatar3 from '../../assets/images/users/avatar-3.jpg'
import avatar6 from '../../assets/images/users/avatar-6.jpg'
import avatar7 from '../../assets/images/users/avatar-7.jpg'
import avatar8 from '../../assets/images/users/avatar-8.jpg'
import { cloneDeep } from 'lodash'
import {
  agentHistoryMessage,
  consumerHistory,
  covertFrindData,
  disconectedUser,
  findOldMessage,
  mapUser,
  readAllMessage,
  recieveMessage,
  selfMessage,
  typedMessage,
  typingMessage,
  updateAgentQr,
  updateBookedFriend,
  updateFriendName,
} from './function'

const initialState: State = {
  active_user: 0,
  userSidebar: true,
  users: [],
  groups: [
    {
      gourpId: 1,
      name: '#General',
      profilePicture: 'Null',
      isGroup: true,
      unRead: 0,
      desc: 'General Group',
      members: [
        { userId: 1, name: 'Sara Muller', profilePicture: 'Null', role: null },
        { userId: 2, name: 'Ossie Wilson', profilePicture: avatar8, role: 'admin' },
        { userId: 3, name: 'Jonathan Miller', profilePicture: 'Null', role: null },
        { userId: 4, name: 'Paul Haynes', profilePicture: avatar7, role: null },
        { userId: 5, name: 'Yana sha', profilePicture: avatar3, role: null },
        { userId: 6, name: 'Steve Walker', profilePicture: avatar6, role: null },
      ],
    },
    {
      gourpId: 2,
      name: '#Reporting',
      profilePicture: 'Null',
      isGroup: true,
      unRead: 23,
      desc: 'reporing Group here...',
      members: [
        { userId: 1, name: 'Sara Muller', profilePicture: 'Null', role: null },
        { userId: 2, name: 'Ossie Wilson', profilePicture: avatar8, role: 'admin' },
        { userId: 3, name: 'Jonathan Miller', profilePicture: 'Null', role: null },
        { userId: 4, name: 'Paul Haynes', profilePicture: avatar7, role: null },
        { userId: 5, name: 'Yana sha', profilePicture: avatar3, role: null },
        { userId: 6, name: 'Steve Walker', profilePicture: avatar6, role: null },
      ],
    },
    {
      gourpId: 3,
      name: '#Designer',
      profilePicture: 'Null',
      isGroup: true,
      unRead: 0,
      isNew: true,
      desc: 'designers Group',
      members: [
        { userId: 1, name: 'Sara Muller', profilePicture: 'Null', role: null },
        { userId: 2, name: 'Ossie Wilson', profilePicture: avatar8, role: 'admin' },
        { userId: 3, name: 'Jonathan Miller', profilePicture: 'Null', role: null },
        { userId: 4, name: 'Paul Haynes', profilePicture: avatar7, role: null },
        { userId: 5, name: 'Yana sha', profilePicture: avatar3, role: null },
        { userId: 6, name: 'Steve Walker', profilePicture: avatar6, role: null },
      ],
    },
    {
      gourpId: 4,
      name: '#Developers',
      profilePicture: 'Null',
      isGroup: true,
      unRead: 0,
      desc: 'developers Group',
      members: [
        { userId: 1, name: 'Sara Muller', profilePicture: 'Null', role: null },
        { userId: 2, name: 'Ossie Wilson', profilePicture: avatar8, role: 'admin' },
        { userId: 3, name: 'Jonathan Miller', profilePicture: 'Null', role: null },
        { userId: 4, name: 'Paul Haynes', profilePicture: avatar7, role: null },
        { userId: 5, name: 'Yana sha', profilePicture: avatar3, role: null },
        { userId: 6, name: 'Steve Walker', profilePicture: avatar6, role: null },
      ],
    },
    {
      gourpId: 5,
      name: '#Project-aplha',
      profilePicture: 'Null',
      isGroup: true,
      unRead: 0,
      isNew: true,
      desc: 'project related Group',
      members: [
        { userId: 1, name: 'Sara Muller', profilePicture: 'Null', role: null },
        { userId: 2, name: 'Ossie Wilson', profilePicture: avatar8, role: 'admin' },
        { userId: 3, name: 'Jonathan Miller', profilePicture: 'Null', role: null },
        { userId: 4, name: 'Paul Haynes', profilePicture: avatar7, role: null },
        { userId: 5, name: 'Yana sha', profilePicture: avatar3, role: null },
        { userId: 6, name: 'Steve Walker', profilePicture: avatar6, role: null },
      ],
    },
    {
      gourpId: 6,
      name: '#Snacks',
      profilePicture: 'Null',
      isGroup: true,
      unRead: 0,
      desc: 'snacks Group',
      members: [
        { userId: 1, name: 'Sara Muller', profilePicture: 'Null', role: null },
        { userId: 2, name: 'Ossie Wilson', profilePicture: avatar8, role: 'admin' },
        { userId: 3, name: 'Jonathan Miller', profilePicture: 'Null', role: null },
        { userId: 4, name: 'Paul Haynes', profilePicture: avatar7, role: null },
        { userId: 5, name: 'Yana sha', profilePicture: avatar3, role: null },
        { userId: 6, name: 'Steve Walker', profilePicture: avatar6, role: null },
      ],
    },
  ],
  contacts: [
    { id: 1, name: 'Albert Rodarte' },
    { id: 2, name: 'Allison Etter' },
    { id: 3, name: 'Craig Smiley' },
    { id: 4, name: 'Daniel Clay' },
    { id: 5, name: 'Doris Brown' },
    { id: 6, name: 'Iris Wells' },
    { id: 7, name: 'Juan Flakes' },
    { id: 8, name: 'John Hall' },
    { id: 9, name: 'Joy Southern' },
    { id: 10, name: 'Mary Farmer' },
    { id: 11, name: 'Mark Messer' },
    { id: 12, name: 'Michael Hinton' },
    { id: 13, name: 'Ossie Wilson' },
    { id: 14, name: 'Phillis Griffin' },
    { id: 15, name: 'Paul Haynes' },
    { id: 16, name: 'Rocky Jackson' },
    { id: 17, name: 'Sara Muller' },
    { id: 18, name: 'Simon Velez' },
    { id: 19, name: 'Steve Walker' },
    { id: 20, name: 'Hanah Mile' },
  ],
}

export default function (state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.chatUserAction):
        return { ...state }
      case getType(actions.activeUserAction):
        draft.active_user = action.payload.userId
        draft.users = readAllMessage(draft.users, action.payload.userId)
        return draft
      case getType(actions.setFullUserAction):
        draft.users = action.payload.fullUser
        return draft
      case getType(actions.addLoggedinUserAction):
        return {
          ...state,
          users: [...state.users, action.payload.userData],
        }
      case getType(actions.createGroupAction):
        return {
          ...state,
          groups: [...state.groups, action.payload.groupData],
        }

      case getType(actions.friendListAction):
        const socketUser = action.payload.users
        const myUUID = action.payload.uuid
        const myAssignID = action.payload.myAssignID
        const userList = [] as User[]
        let firstAssignID = 1
        let hasAssign = false
        socketUser.forEach((element: UserSocket, index: number) => {
          if (element.assignID === myUUID && element.assignID !== '' && !hasAssign) {
            firstAssignID = index + 1
            hasAssign = true
          }
          if (!element.socketID) {
            element.socketID = 'dummy'
          }

          userList.push(covertFrindData(index, element, draft.users))
        })
        if (socketUser.length > 0) {
          if (socketUser[0].userType === 'agent') {
            const myActivefriend = cloneDeep(userList).find(item => item.userID === myAssignID)
            draft.active_user = myActivefriend?.id || 1
          } else {
            if (hasAssign) {
              draft.active_user = firstAssignID
            }
          }
        }

        draft.users = userList
        return draft
      case getType(actions.appendFriendAction):
        const newUser = action.payload.user
        draft.users = mapUser(draft.users, newUser)

        return draft
      case getType(actions.disconnectedFriendAction):
        draft.users = disconectedUser(draft.users, action.payload.user)
        return draft
      case getType(actions.recieveChatFromAction):
        draft.users = recieveMessage(
          draft.users,
          action.payload.recieveMessage,
          draft.active_user,
          action.payload.uuid,
        )

        return draft
      case getType(actions.selfChatFromAction):
        draft.users = selfMessage(draft.users, action.payload.recieveMessage)
        return draft

      case getType(actions.consumerHistoryChatAction):
        const messages = action.payload.messages
        const newUserList = cloneDeep(draft.users) as User[]
        if (newUserList && newUserList.length > 0) {
          newUserList.map(item => {
            item.messages = consumerHistory(messages) || []
          })
          //newUserList[0].messages = consumerHistory(messages) || []
        }
        draft.users = newUserList

        return draft

      case getType(actions.agentHistoryChatAction):
        draft.users = agentHistoryMessage(draft.active_user, draft.users, action.payload.messages)
        return draft

      case getType(actions.onTypingAction):
        draft.users = typingMessage(draft.users, action.payload.user)
        return draft
      case getType(actions.onTypedAction):
        draft.users = typedMessage(draft.users, action.payload.user)
        return draft
      case getType(actions.openUserSidebarAction):
        draft.userSidebar = true
        return draft
      case getType(actions.closeUserSidebarAction):
        draft.userSidebar = false
        return draft
      case getType(actions.updateFriendName):
        const newName = action.payload.name
        const uuid = action.payload.uuid
        draft.users = updateFriendName(draft.users, newName, uuid)
        return draft
      case getType(actions.onBookedFriend):
        const newBooked = action.payload.user
        const isBookAction = newBooked?.assignID !== '' && newBooked?.assignID !== 'end-case'
        const isSelfBook = newBooked?.assignID === action.payload.uuid
        const currentUser = draft.users?.find(item => item.id === draft.active_user)
        draft.users = updateBookedFriend(draft.users, newBooked, isSelfBook, isBookAction)
        if (
          currentUser &&
          currentUser.userID === newBooked.userID &&
          action.payload.uuid !== newBooked.userID
        ) {
          draft.active_user = 0
        }

        return draft

      case getType(actions.onUpdateAgentQrAction):
        draft.users = updateAgentQr(draft.users, action.payload.user)
        return draft

      case getType(actions.onChangeAgent):
        const newId = action.payload.id
        const findUser = draft.users?.find(item => item.userSocket.userID === newId)
        draft.active_user = findUser?.id || 1
        return draft
      default:
        return state
    }
  })
}
