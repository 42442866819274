import React, { useEffect } from 'react'
import { Col, Empty, Row, Timeline } from 'antd'
import { DetailDrawer } from 'Components/common/MainLayout'
import styled from 'styled-components'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { LoadingView } from 'Components/modal'
import { MyTheme } from 'Components/common/Theme'
import { formatDateTime, getCustomerTaskStatus } from 'helpers/format'
import Label from 'Components/text/Label'
import LinkText from 'Components/text/LinkText'

type Props = {
  isShow: boolean
  onClose: () => void
  id: number
} & ReduxProps

const Logs = (props: Props) => {
  const { isShow, onClose, id } = props
  const list = [] as any[]
  const isPending = false

  const dispatch = useDispatch()

  const onGetDataList = (): void => {
    // const req: LogsReq = {
    //   id: id,
    // }
    // dispatch(getPurchaseLogsAPI(req, currentUser.accessToken, currentUser?.activeMerchant?.uuid || ''))
  }

  useEffect(() => {
    if (isShow) {
      onGetDataList()
    }
  }, [isShow, id])

  const getTimeLines = () => {
    const timeline = [] as any
    list &&
      list.map((item: any, index: number) => {
        if (item && item.logs && item.logs.length > 0) {
          const toStatus = getCustomerTaskStatus(item.logs[0].to)
          const sameStatus = item.logs[0].from === item.logs[0].to
          const title = sameStatus ? `แก้ไข${toStatus.text}` : `แก้ไขสถานะเป็น ${toStatus.text}`
          timeline.unshift(
            <Timeline.Item key={index} dot={<StartDot style={{ backgroundColor: toStatus.color }} />}>
              <span style={{ color: toStatus.color }}>{toStatus.text}</span>
              <br />
              <LabelStyle>วัน/เวลา : {formatDateTime(item.create_at)}</LabelStyle> <br />
              <LabelStyle>ผู้ทำรายการ : {item.username}</LabelStyle>
            </Timeline.Item>,
          )
        }
      })
    timeline.push(
      <Timeline.Item key="start" dot={<StartDot />}>
        <LabelStyle>เริ่มต้น</LabelStyle>
      </Timeline.Item>,
    )
    return timeline
  }

  return (
    <DetailDrawer
      title={'สถานะงาน'}
      width={'30%'}
      onCloseDrawer={onClose}
      visible={isShow}
      footer={null}
      maskClosable={true}
    >
      {/* <LoadingView isShow={event.IsLoading} /> */}
      <Row>
        <Col span={24}>
          {list && list.length > 0 ? (
            <Timeline pending={isPending ? <LabelStyle>รอดำเนินการ...</LabelStyle> : null}>
              {getTimeLines()}
            </Timeline>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Col>
      </Row>
    </DetailDrawer>
  )
}

const LabelStyle = styled(Label)`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
`

const StartDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
`

const mapStateToProps = (state: RootState) => {
  return {
    // currentUser: selectCurrentUser(state),
    // event: selectLogsEvent(state),
    // list: selectLogs(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(Logs)
