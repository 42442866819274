import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { Card, Col, Divider, message, Row, Space, Statistic, Tabs } from 'antd'
import { PrimaryButton } from 'Components/button'
import { selectChatActiveUser, selectChatUsers } from 'Store/chat/selector'
import { findUserById } from 'Store/chat/function'
import { ProfileAvatar } from 'Components/avatar'
import styled from 'styled-components'
import Theme, { MyTheme } from 'Components/common/Theme'
import { CloseCircleOutlined, SearchOutlined, SwapOutlined } from '@ant-design/icons'
import { AppInput, AppSelect, AppTextArea, MultiUploadForm } from 'Components/form'
import { formValidation, getMenuSelect, isFormError, isFormRequired, numberWithComma } from 'helpers/format'
import { CustomerStatus } from 'helpers/constanst'
import {
  getBrandBySlugListAPI,
  getBrandListAPI,
  getCustomerByUUIDAPI,
  updateCustomerAPI,
} from 'Store/chaiyo-customer/api'
import {
  BrandReq,
  BrandSlugReq,
  GetCustomerReq,
  UpdateCustomerReq,
  YearData,
} from 'Store/chaiyo-customer/types'
import { selectLoginData } from 'Store/user/selector'
import {
  selectActionEvent,
  selectBrandList,
  selectCustomerInfo,
  selectLastCreateTask,
  selectModelList,
  selectYearList,
} from 'Store/chaiyo-customer/selector'
import {
  clearEventAction,
  getYearByModelDataAction,
  resetBrandDataAction,
} from 'Store/chaiyo-customer/actions'
import { ConfirmDailog, EventModal } from 'Components/modal'
import { cloneDeep } from 'lodash'
import CustomerTaskDetail from '../Agent/ChaiyoCustomerTask/detail'
import { getSelfUser } from 'helpers/account'
import { appConfig } from 'config'
import { consoleLog } from 'helpers/utils'
const { TabPane } = Tabs

type Props = {
  isAgent?: boolean
  isNotAssign?: boolean
} & ReduxProps
const ChaiyoForm: React.FunctionComponent<Props> = props => {
  const {
    loginData,
    active_user,
    users,
    isAgent = false,
    isNotAssign = false,
    customerInfo,
    actionEvent,
    lastCreateTask,
    onBookFriend,
    brandList,
    modelList,
    yearList,
  } = props
  const selfUser = getSelfUser()

  const nomalRequireFeild = [
    'first_name',
    'last_name',
    'mobile',
    //'address',
    'car_brand',
    'car_model',
    'car_year',
    'amount_redbook',
    'amount_limit',
    'amount_rate',
    'car_paint',
    'car_registration',
  ]
  const [formError, setFormError] = useState([] as string[])
  const [confirmModal, setConfirmModal] = useState(false)
  const [returnModal, setReturnModal] = useState(false)
  const [endConversationModal, setEndConversationModal] = useState(false)
  const [taskDrawer, setTaskDrawer] = useState(false)
  const [activeYear, setActiveYear] = useState(undefined as YearData | undefined)
  const activeUser = findUserById(users, active_user)
  const requireFeild = nomalRequireFeild
  consoleLog('activeUser', activeUser)
  const initForm = {
    first_name: '',
    last_name: '',
    address: '',
    amount_limit: 0,
    amount_rate: 0,
    amount_redbook: 0,
    car_paint: '',
    car_registration: '',
    images: [],
    mobile: '',
    car_brand: '',
    car_model: '',
    car_year: '',
    qr_reference: '',
    status: CustomerStatus.DRAFT,
    toUser: undefined,
    fromUser: undefined,
  } as UpdateCustomerReq
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const dispatch = useDispatch()

  const onUpdateCustomer = (status: CustomerStatus): void => {
    setConfirmModal(false)
    const req: UpdateCustomerReq = {
      ...formData,
      status: status,
      qr_reference: activeUser?.userSocket?.agentID || '',
      toUser: activeUser?.userSocket,
      fromUser: selfUser,
    }
    dispatch(updateCustomerAPI(req, activeUser?.userID || '', loginData?.access_token || ''))
  }

  const onGetCustomerInfo = (): void => {
    const req: GetCustomerReq = {
      uuid: activeUser?.userID || '',
    }
    dispatch(getCustomerByUUIDAPI(req, loginData?.access_token || ''))
  }

  const onGetBrandList = (): void => {
    const req: BrandReq = {}
    dispatch(getBrandListAPI(req, loginData?.access_token || ''))
  }

  const onGetBrandBySlugList = (slug: string): void => {
    const req: BrandSlugReq = {
      slug,
    }
    dispatch(getBrandBySlugListAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    onGetBrandList()
  }, [])

  useEffect(() => {
    if (formData.car_brand) {
      onGetBrandBySlugList(formData.car_brand || '')
    } else {
      resetData()
    }
  }, [formData.car_brand])

  useEffect(() => {
    if (formData.car_model) {
      getYearData()
    }
  }, [formData.car_model, modelList])

  useEffect(() => {
    if (formData.car_year && formData.car_model) {
      const year = yearList && yearList.find((item: YearData) => item.description === formData.car_year)
      setActiveYear(year)
    } else {
      setActiveYear(undefined)
    }
  }, [formData.car_year, yearList, active_user])

  useEffect(() => {
    if (customerInfo) {
      const form = {
        first_name: customerInfo?.first_name || '',
        last_name: customerInfo?.last_name || '',
        mobile: customerInfo?.mobile || '',
        address: customerInfo?.car_loan?.address || '',
        amount_limit: customerInfo?.car_loan?.amount_limit || 0,
        amount_rate: customerInfo?.car_loan?.amount_rate || 0,
        amount_redbook: customerInfo?.car_loan?.amount_redbook || 0,
        car_brand: customerInfo?.car_loan?.car_brand || '',
        car_model: customerInfo?.car_loan?.car_model || '',
        car_year: customerInfo?.car_loan?.car_year || '',
        car_paint: customerInfo?.car_loan?.car_paint || '',
        car_registration: customerInfo?.car_loan?.car_registration || '',
        images: customerInfo?.car_loan?.images || [],
      } as UpdateCustomerReq
      setFormData({ ...form })
    }
  }, [customerInfo])

  useEffect(() => {
    if (isAgent && !isNotAssign) {
      onGetCustomerInfo()
    }
  }, [active_user])

  useEffect(() => {
    setFormError([])
    setFormData(cloneDeep(initForm))
  }, [active_user])

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    setFormError([...error])
    return error.length > 0 ? false : true
  }

  const onSaveDraft = () => {
    onUpdateCustomer(CustomerStatus.DRAFT)
  }

  const onOpenCase = () => {
    if (!validateForm()) {
      message.error('กรุณาระบุข้อมูลให้ครบถ้วน')
      return
    }
    setConfirmModal(true)
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const onUpdateData = (value: any, key: string) => {
    if (key === 'mobile' && value.length > 10) return
    setFormData({
      ...formData,
      [key]: value,
    })
  }

  const onUpdateCarBrand = (value: any, key: string) => {
    setActiveYear(undefined)
    setFormData({
      ...formData,
      [key]: value,
      car_model: '',
      car_year: '',
      amount_redbook: 0,
    })
  }

  const onUpdateCarModel = (value: any, key: string) => {
    setActiveYear(undefined)
    setFormData({
      ...formData,
      [key]: value,
      car_year: '',
      amount_redbook: 0,
    })
  }

  const onUpdateCarYear = (value: any, key: string) => {
    const year = yearList && yearList.find((item: YearData) => item.year === value)
    setFormData({
      ...formData,
      [key]: value,
      amount_redbook: parseFloat(year?.price || ''),
    })
  }

  const onUpdateCarPrice = (value: any) => {
    setFormData({
      ...formData,
      amount_redbook: parseFloat(value || 0),
    })
  }

  const resetData = (): void => {
    formData.car_model = ''
    formData.car_year = ''
    dispatch(resetBrandDataAction())
  }

  const getYearData = (): void => {
    dispatch(getYearByModelDataAction({ model: formData.car_model || '' }))
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventSuccess = (): void => {
    onGetCustomerInfo()
    clearEvent()
    if (lastCreateTask?.id) {
      setTaskDrawer(true)
    }
  }

  const onReturn = () => {
    setReturnModal(false)
    onBookFriend('return')
  }

  const onEndChat = () => {
    setEndConversationModal(false)
    onBookFriend('end')
  }

  const ConfirmOpenCaseModal = (
    <ConfirmDailog
      title={`คุณแน่ใจหรือว่าต้องการเปิดเคสใหม่ ?`}
      description="กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนยืนยันการเปิดเคส"
      isShow={confirmModal}
      confirmBtnTxt="ยืนยันเปิดเคส"
      handleOk={() => onUpdateCustomer(CustomerStatus.OPEN)}
      handleCancel={() => setConfirmModal(false)}
    />
  )

  const ConfirmReturnModal = (
    <ConfirmDailog
      title={`คุณต้องการโอนงานไปยังห้องแชททั้งหมดใช่หรือไม่ ?`}
      description=""
      isShow={returnModal}
      confirmBtnTxt="ยืนยัน"
      handleOk={onReturn}
      handleCancel={() => setReturnModal(false)}
    />
  )

  const ConfirmEndConversationModal = (
    <ConfirmDailog
      title={`คุณต้องการจบการสนทนากับลูกค้าใช่หรือไม่ ?`}
      description="หากจบการสนทนาแชทจะหายไปจากงานที่มอบหมายให้ฉัน"
      isShow={endConversationModal}
      confirmBtnTxt="ยืนยัน"
      handleOk={onEndChat}
      handleCancel={() => setEndConversationModal(false)}
    />
  )

  const valStyle = {
    color: Theme.colors.black,
    fontFamily: Theme.fontFamily.regular,
    fontSize: Theme.typography.body4,
  }

  const cutomerInfo = () => {
    return (
      <Row justify="start" align="top" gutter={[8, 8]}>
        <Col>
          <ProfileAvatar size={45} name={activeUser?.name} logo={activeUser?.profilePicture} />
        </Col>
        <Col>
          <Space direction="vertical" size="small">
            <Name>{activeUser?.name}</Name>
            {/* <Text>วันที่เข้าร่วม ****</Text> */}
            <Space size="small">
              <PrimaryButton
                text={'โอนงาน'}
                onClick={() => setReturnModal(true)}
                size="small"
                btnType="Cancel"
                icon={<SwapOutlined />}
              />
              <PrimaryButton
                text={'จบการสนทนา'}
                onClick={() => setEndConversationModal(true)}
                size="small"
                btnType="Reject"
                icon={<CloseCircleOutlined />}
              />
            </Space>
          </Space>
        </Col>
      </Row>
    )
  }

  const customerForm = () => {
    return (
      <Row justify="start" align="top" gutter={8}>
        <Col span={24}>
          <Name>ข้อมูลลูกค้า</Name>
        </Col>
        <Col span={24}>
          <AppInput
            name="first_name"
            label="ชื่อ"
            isRequired={isRequired('first_name')}
            isError={isError('first_name')}
            value={formData.first_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col span={24}>
          <AppInput
            name="last_name"
            label="นามสกุล"
            isRequired={isRequired('last_name')}
            isError={isError('last_name')}
            value={formData.last_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col span={24}>
          <AppInput
            name="mobile"
            label="เบอร์โทรศัพท์"
            placeHolder="ตัวอย่าง 0888888888"
            isRequired={isRequired('mobile')}
            isError={isError('mobile')}
            value={formData.mobile}
            onChange={onUpdateData}
          />
        </Col>
        <Col span={24}>
          <AppTextArea
            label="ที่อยู่"
            placeHolder="ที่อยู่"
            isRequired={isRequired('address')}
            isError={isError('address')}
            name="address"
            rows={2}
            value={formData.address}
            onChange={onUpdateData}
          />
        </Col>
      </Row>
    )
  }

  const renderPriceChoices = (key: string, name: string) => {
    return (
      <div onClick={() => onUpdateCarPrice(activeYear?.[key] || 0)}>
        <CardPrice className={formData.amount_redbook == (activeYear?.[key] || 0) ? 'actived' : ''}>
          <Statistic
            title={name}
            value={activeYear?.[key] || 0}
            suffix={'บาท'}
            valueStyle={valStyle}
            formatter={(val: any) => numberWithComma(parseFloat(val) || 0)}
          />
        </CardPrice>
      </div>
    )
  }

  const getExternalLink = () => {
    return (
      <ExternalLink href={appConfig.externalChaiyoLink} target="_blank" rel="noopener noreferrer">
        &nbsp;
        <SearchOutlined /> ตรวจสอบข้อมูลสาขา, ราคาประเมิน, คำนวนค่างวด
      </ExternalLink>
    )
  }

  const carForm = () => {
    return (
      <Row justify="center" align="top" gutter={8}>
        {/* <Col span={24}>
          <Name>ข้อมูลรถยนต์</Name>
        </Col> */}
        <Col span={24}>
          {getExternalLink()}
          <Divider />
        </Col>
        <Col md={24} xs={24}>
          <AppSelect
            name="car_brand"
            label="ยี่ห้อรถ"
            isRequired={isRequired('car_brand')}
            isError={isError('car_brand')}
            value={formData.car_brand}
            onChange={onUpdateCarBrand}
            menu={getMenuSelect(brandList, 'name', 'slug')}
            allowClear={false}
          />
        </Col>
        <Col md={24} xs={24}>
          <AppSelect
            name="car_model"
            label="รุ่นรถ"
            isRequired={isRequired('car_model')}
            isError={isError('car_model')}
            value={formData.car_model}
            onChange={onUpdateCarModel}
            menu={getMenuSelect(modelList, 'name', 'name')}
            disabled={formData.car_brand == ''}
            allowClear={false}
          />
        </Col>
        <Col md={24} xs={24}>
          <AppSelect
            name="car_year"
            label="ปีจดทะเบียนรถ"
            isRequired={isRequired('car_year')}
            isError={isError('car_year')}
            value={formData.car_year}
            onChange={onUpdateCarYear}
            menu={getMenuSelect(yearList, 'description', 'description')}
            disabled={formData.car_model == ''}
            allowClear={false}
          />
        </Col>
        {/* <Col span={24}>
          <Divider />
        </Col> */}
        {activeYear && (
          <>
            <Col span={24}>
              <CarName> เลือก Range ราคา Redbook : {activeYear?.description || ''} </CarName>
            </Col>
            <Col span={24}>
              {/* {renderPriceChoices('price', 'ราคา')} */}
              {renderPriceChoices('l1', 'ราคา L1')}
              {renderPriceChoices('l2', 'ราคา L2')}
              {/* {renderPriceChoices('l3', 'ราคา L3')} */}
            </Col>
          </>
        )}
        <Col span={24}>
          <Divider />
        </Col>
        {/* {(formData?.amount_redbook || 0) > 0 && (
          <>
            <Col span={24}>
              <Divider />
            </Col>
            <Col span={24}>
              <ResultWrapper>
                ราคาประเมิน <Price>{numberWithComma(formData?.amount_redbook || 0, true)} </Price> บาท
              </ResultWrapper>
            </Col>
            <Col span={24}>
              <Divider />
            </Col>
          </>
        )} */}
        <Col span={24}>
          <AppInput
            name="amount_redbook"
            label="Range ราคา Redbook"
            isRequired={isRequired('amount_redbook')}
            isError={isError('amount_redbook')}
            value={formData.amount_redbook}
            onChange={onUpdateData}
            type="number"
            resProps={{ disabled: true }}
          />
        </Col>
        <Col span={24}>
          <AppInput
            name="amount_limit"
            label="วงเงินที่ลูกค้าต้องการ"
            isRequired={isRequired('amount_limit')}
            isError={isError('amount_limit')}
            value={formData.amount_limit}
            onChange={onUpdateData}
            type="number"
          />
        </Col>
        <Col span={24}>
          <AppInput
            name="amount_rate"
            label="อัตราผ่อนที่ชำระได้ /เดือน"
            isRequired={isRequired('amount_rate')}
            isError={isError('amount_rate')}
            value={formData.amount_rate}
            onChange={onUpdateData}
            type="number"
          />
        </Col>
        <Col span={24}>
          <AppInput
            name="car_paint"
            label="สีรถ"
            isRequired={isRequired('car_paint')}
            isError={isError('car_paint')}
            value={formData.car_paint}
            onChange={onUpdateData}
          />
        </Col>
        <Col span={24}>
          <AppInput
            name="car_registration"
            label="ทะเบียน"
            isRequired={isRequired('car_registration')}
            isError={isError('car_registration')}
            value={formData.car_registration}
            onChange={onUpdateData}
          />
        </Col>
        <Col span={24}>
          <MultiUploadForm
            label="รูปภาพรถยนต์ (อัปโหลดได้หลายรูปภาพ)"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={onUpdateData}
            files={formData.images}
            listType="picture-card"
            name="images"
            readOnly={false}
            maxFileSize={5000}
          />
        </Col>
      </Row>
    )
  }

  const DetailDrawer = (
    <CustomerTaskDetail
      id={lastCreateTask?.id || 0}
      visible={taskDrawer}
      onCloseDrawer={() => setTaskDrawer(false)}
      onSuccess={onEventSuccess}
    />
  )

  return (
    <div className="profile-info">
      <EventModal
        event={[actionEvent]}
        enable={true}
        successCalback={onEventSuccess}
        errorCalback={clearEvent}
      />
      {ConfirmOpenCaseModal}
      {ConfirmReturnModal}
      {ConfirmEndConversationModal}
      {DetailDrawer}
      <Row>
        <Col span={24}>
          <ContentWrapper>
            <Row>
              <Col span={24}>{cutomerInfo()}</Col>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <Tabs defaultActiveKey="1">
              <TabPane tab={`ข้อมูลลูกค้า`} key="1">
                {customerForm()}
              </TabPane>
              <TabPane tab={`ข้อมูลรถยนต์`} key="2">
                {carForm()}
              </TabPane>
            </Tabs>
            <Col span={24}>
              <Row justify="center" gutter={[8, 8]}>
                <Col span={12}>
                  <PrimaryButton text={'บันทึกแบบร่าง'} onClick={onSaveDraft} btnType="Cancel" />
                </Col>
                <Col span={12}>
                  <PrimaryButton text={'เปิดเคส'} onClick={onOpenCase} />
                </Col>
              </Row>
            </Col>
          </ContentWrapper>
        </Col>
      </Row>
    </div>
  )
}

const ContentWrapper = styled.div`
  padding: 10px;
`

const Name = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.title3};
`

const CarName = styled.span`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.medium};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
`

const CardPrice = styled(Card.Grid)`
  width: 50%;
  padding: 12px;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
  .ant-statistic-title {
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body5};
  }

  &.actived {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
    span,
    div {
      color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
    }
  }

  @media only screen and (max-width: 720px) {
    width: 50%;
  }
`

const ExternalLink = styled.a`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
`

const mapStateToProps = (state: RootState): any => {
  return {
    users: selectChatUsers(state),
    active_user: selectChatActiveUser(state),
    loginData: selectLoginData(state),
    customerInfo: selectCustomerInfo(state),
    actionEvent: selectActionEvent(state),
    lastCreateTask: selectLastCreateTask(state),
    brandList: selectBrandList(state),
    modelList: selectModelList(state),
    yearList: selectYearList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(ChaiyoForm)
