import withAPIAction from 'helpers/withAPIAction'
import { DetailReq, ListReq, UpdateReq, CountReq, AssignReq } from './types'
import {
  getDetailAction,
  getListAction,
  countCustomerFileTabAction,
  uploadFileAction,
  assignCustomerAction,
  updateAction,
} from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'
import { getRequestString } from 'helpers/format'

export const assignCustomerFileAPI = (data: AssignReq, uuid: string, token: string) => {
  const endPoint = APIPath.assignCustomerFile.replace(':uuid', uuid)
  return withAPIAction<AssignReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(assignCustomerAction)
}

export const getCustomerFileTaskListAPI = (data: ListReq, token: string, isMe: boolean = false) => {
  const endPoint = isMe ? APIPath.myCustomerFileTaskList : APIPath.customerFileTaskList
  return withAPIAction<ListReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${endPoint}?${getRequestString(data)}`,
    data,
    token,
  })(getListAction)
}

export const getCustomerFileDetailAPI = (data: DetailReq, token: string) => {
  const endPoint = APIPath.getCustomerFileByUUID.replace(':uuid', data.uuid)
  return withAPIAction<DetailReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getDetailAction)
}

export const updateCustomerFileInfoAPI = (data: UpdateReq, token: string) => {
  const endPoint = APIPath.updateCustomerFileInfo.replace(':uuid', data.uuid.toString())
  return withAPIAction<UpdateReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateAction)
}

export const countCustomerFileAPI = (data: CountReq, token: string) => {
  return withAPIAction<CountReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.countCustomerFile,
    data,
    token,
  })(countCustomerFileTabAction)
}

export const uploadFileApi = (data: FormData, token: string) => {
  return withAPIAction<FormData>({
    method: 'POST',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.importCustomerFile,
    data,
    token,
  })(uploadFileAction)
}
