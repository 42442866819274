import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import {
  GetCustomerReq,
  ActionRes,
  GetCustomerRes,
  UpdateCustomerReq,
  UpdateCustomerRes,
  UpdateCustomerSocketReq,
  ListReq,
  ListRes,
  DetailReq,
  DetailRes,
  UpdateReq,
  UpdateStatusReq,
  CountReq,
  CountRes,
  SaleStatusReq,
  SaleStatusRes,
} from './types'

export const getCustomerInfoAction = createAsyncAction(
  'CUSTOMER/GET_CUS_INFO_REQ',
  'CUSTOMER/GET_CUS_INFO_SUCCESS',
  'CUSTOMER/GET_CUS_INFO_FAILURE',
)<GetCustomerReq, GetCustomerRes, Error>()

export const updateCustomerAction = createAsyncAction(
  'CUSTOMER/UPDATE_CUS_INFO_REQ',
  'CUSTOMER/UPDATE_CUS_INFO_SUCCESS',
  'CUSTOMER/UPDATE_CUS_INFO_FAILURE',
)<UpdateCustomerReq, UpdateCustomerRes, Error>()

export const updateCustomerSocketAction = createAsyncAction(
  'CUSTOMER/UPDATE_CUS_SOCKET_REQ',
  'CUSTOMER/UPDATE_CUS_SOCKET_SUCCESS',
  'CUSTOMER/UPDATE_CUS_SOCKET_FAILURE',
)<UpdateCustomerSocketReq, ActionRes, Error>()

export const getListAction = createAsyncAction(
  'CUSTOMER/GET_CUSTOMER_LIST_REQ',
  'CUSTOMER/GET_CUSTOMER_LIST_SUCCESS',
  'CUSTOMER/GET_CUSTOMER_LIST_FAILURE',
)<ListReq, ListRes, Error>()

export const getDetailAction = createAsyncAction(
  'CUSTOMER/GET_CUSTOMER_DETAIL_REQ',
  'CUSTOMER/GET_CUSTOMER_DETAIL_SUCCESS',
  'CUSTOMER/GET_CUSTOMER_DETAIL_FAILURE',
)<DetailReq, DetailRes, Error>()

export const updateAction = createAsyncAction(
  'CUSTOMER/UPDATE_CUSTOMER_REQ',
  'CUSTOMER/UPDATE_CUSTOMER_SUCCESS',
  'CUSTOMER/UPDATE_CUSTOMER_FAILURE',
)<UpdateReq, ActionRes, Error>()

export const updateCustomerStatusAction = createAsyncAction(
  'CUSTOMER/UPDATE_CUSTOMER_STATUS_REQ',
  'CUSTOMER/UPDATE_CUSTOMER_STATUS_SUCCESS',
  'CUSTOMER/UPDATE_CUSTOMER_STATUS_FAILURE',
)<UpdateStatusReq, ActionRes, Error>()

export const countCustomerTabAction = createAsyncAction(
  'CUSTOMER/COUNT_CUSTOMER_TAB_REQ',
  'CUSTOMER/COUNT_CUSTOMER_TAB_SUCCESS',
  'CUSTOMER/COUNT_CUSTOMER_TAB_FAILURE',
)<CountReq, CountRes, Error>()

export const clearEventAction = createStandardAction('CUSTOMER/CLEAR_EVENT')<void>()

export const getSaleStatusAction = createAsyncAction(
  'CUSTOMER/GET_SALE_STATUS_REQ',
  'CUSTOMER/GET_SALE_STATUS_SUCCESS',
  'CUSTOMER/GET_SALE_STATUS_FAILURE',
)<SaleStatusReq, SaleStatusRes, Error>()
