import React from 'react'
import { Tag } from 'antd'

type Props = {
  text: string
  isPass?: boolean
  color?: string
}

const StatusTag = (props: Props) => {
  const { text, isPass = true, color = '' } = props
  let tagColor = ''
  if (color) {
    tagColor = color
  } else {
    tagColor = isPass ? 'green' : 'red'
  }

  return <Tag color={tagColor}>{text}</Tag>
}

export default StatusTag
