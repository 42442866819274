import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { Col, Row, Space } from 'antd'
import { PrimaryButton } from 'Components/button'
import {
  formValidation,
  isFormError,
  isFormRequired,
  getAddressFormSubdistrictID,
  validateEmail,
} from 'helpers/format'
import {
  selectActionEvent,
  selectDetail,
  selectDetailEvent,
  selectPartnerInfo,
} from 'Store/partner-qr/selector'
import { clearEventAction } from 'Store/partner-qr/actions'
import EventModal from 'Components/modal/EventModal'
import LoadingView from 'Components/modal/LoadingView'
import { DetailDrawer } from 'Components/common/MainLayout'
import { ConfirmDailog } from 'Components/modal'
import { menuBankAccount } from 'helpers/constanst'
import { selectLoginData } from 'Store/user/selector'
import BoxWrapper from 'Components/common/Layout/BoxWrapper'
import { AppInput, AppSelect } from 'Components/form'
import AppAutocomplete from 'Components/form/AppAutocomplete'
import { selectAddressData } from 'Store/register/selector'
import { CreateReq, PartnerInfoReq } from 'Store/partner-qr/types'
import { AddressData, GetAddressReq } from 'Store/register/types'
import { OptionProps } from 'Components/form/InputAutocomplete'
import {
  createQrPartnerAPI,
  getPartnerCodesAPI,
  getPartnerInfoAPI,
  getPartnerStructerAPI,
} from 'Store/partner-qr/api'
import { selectPartnerCode, selectPartnerStructer } from 'Store/partner-qr/selector'
import AppPartnerStructer from 'Components/form/AppPartnerStructer'
import { getAddressAPI } from 'Store/register/api'
import { consoleLog } from 'helpers/utils'

type Props = ReduxProps & {
  visible: boolean
  onCloseDrawer: () => void
  onSuccess?: () => void
}

const FormCreate: React.FunctionComponent<Props> = props => {
  const {
    onCloseDrawer,
    visible,
    event,
    onSuccess = () => {},
    detailEvent,
    loginData,
    addressList,
    partnerStructer,
    partnerCodes,
    partnerInfo,
  } = props

  const requireFeild = [
    'address',
    'bank_account_name',
    'bank_account_no',
    // 'bank_branch',
    'bank_name',
    'channel_code',
    'commission',
    'company_code',
    'corporate_name',
    'corporate_no',
    'email',
    'first_name',
    'industry_code',
    'last_name',
    'mobile',
    'postcode',
    'qr_name',
    'select_parents',
  ] as string[]
  const initForm = {
    address: '',
    bank_account_name: '',
    bank_account_no: '',
    bank_branch: '',
    bank_name: '',
    channel_code: '',
    commission: '',
    company_code: '',
    corporate_name: '',
    corporate_no: '',
    district_id: 0,
    email: '',
    first_name: '',
    industry_code: '',
    last_name: '',
    mobile: '',
    postcode: '',
    province_id: 0,
    qr_name: '',
    sub_district_id: 0,
    user_partner_id: 0,
  } as CreateReq
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const [formError, setFormError] = useState([] as string[])
  const [confirmDialog, setConfirmDialog] = useState(false)
  const dispatch = useDispatch()
  const isLevel1 = formData?.select_parents && formData?.select_parents[0] && !formData?.select_parents[1]

  useEffect(() => {
    if (visible) {
      getMasterData()
    }

    if (!visible) {
      setFormError([])
      setFormData(cloneDeep(initForm))
    }
  }, [visible])

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  useEffect(() => {
    if (partnerInfo) {
      setFormData({
        ...formData,
        address: partnerInfo?.address,
        postcode: partnerInfo?.postcode,
        corporate_no: partnerInfo?.corporate_no,
        corporate_name: partnerInfo?.corporate_name,
        bank_account_name: partnerInfo?.bank_account_name,
        bank_account_no: partnerInfo?.bank_account_no,
        bank_branch: partnerInfo?.bank_branch,
        bank_name: partnerInfo?.bank_name,
        sub_district_id: partnerInfo?.sub_district_id,
        company_code: partnerInfo?.company_code,
        channel_code: partnerInfo?.channel_code,
        industry_code: partnerInfo?.industry_code,
      })
    }
  }, [partnerInfo])

  useEffect(() => {
    if (addressList) {
      const addressItem = getAddressFormSubdistrictID(addressList, partnerInfo?.sub_district_id || 0)
      setFormData({
        ...formData,
        address_item: addressItem,
      })
    }
  }, [formData.sub_district_id, addressList])

  const onCreate = (): void => {
    if (!validateForm()) return
    const partnerID =
      formData?.select_parents && formData?.select_parents.length > 0
        ? formData.select_parents[formData?.select_parents.length - 1]
        : 0
    const req: CreateReq = {
      ...formData,
      user_partner_id: partnerID,
      sub_district_id: formData.address_item?.sub_district.id || 0,
      district_id: formData.address_item?.district.id || 0,
      province_id: formData.address_item?.province.id || 0,
      commission: String(formData?.commission || ''),
    }
    delete req.address_item
    delete req.select_parents
    dispatch(createQrPartnerAPI(req, loginData?.access_token || ''))
  }

  const getMasterData = (): void => {
    dispatch(getPartnerStructerAPI({}, loginData?.access_token || ''))
    dispatch(getPartnerCodesAPI({}, loginData?.access_token || ''))
  }

  const onGetAddressList = (): void => {
    const req: GetAddressReq = {
      postcode: formData.postcode,
    }
    dispatch(getAddressAPI(req))
  }

  const onGetPartnerInfo = (id: number): void => {
    const req: PartnerInfoReq = {
      id,
    }
    dispatch(getPartnerInfoAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    if (formData.postcode && formData.postcode.length === 5) {
      onGetAddressList()
    } else {
      onUpdateData(undefined, 'address_item')
    }
  }, [formData.postcode])

  useEffect(() => {
    if (formData.select_parents) {
      const partnerID =
        formData?.select_parents && formData?.select_parents.length > 1
          ? formData.select_parents[1]
          : formData?.select_parents[formData?.select_parents.length - 1]
      onGetPartnerInfo(partnerID)
    }
  }, [formData.select_parents])

  const onUpdateData = (val: any, key: string) => {
    if (key === 'mobile' && val.length > 10) return
    if (key === 'corporate_no' && val.length > 13) return
    let value = cloneDeep(val)
    if (key === 'commission') {
      if (parseFloat(val) > 100) {
        value = 100
      }
    }

    if (key === 'company_code') {
      if (val.length > 2) {
        return
      }
    }
    setFormData({
      ...formData,
      [key]: value,
    })
  }

  const validateForm = () => {
    let error = formValidation(requireFeild, formData)
    if (error.includes('channel_code') && formData.channel_code === '0') {
      error = error.filter(key => key !== 'channel_code')
    }
    if (
      error.includes('industry_code') &&
      (formData.industry_code === '0' || formData.industry_code === '00')
    ) {
      error = error.filter(key => key !== 'industry_code')
    }

    // if (error.includes('company_code') && !isLevel1) {
    //   error = error.filter(key => key !== 'company_code')
    // }

    if (formData.email) {
      if (!validateEmail(formData.email)) {
        error.push('email_format')
      }
    }
    setFormError([...error])
    consoleLog('error', error)
    return error.length > 0 ? false : true
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventSuccess = (): void => {
    clearEvent()
    onSuccess()
    onCloseDrawer()
    if (confirmDialog) {
      setConfirmDialog(false)
    }
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const onSelectAddress = (subdistricID: number) => {
    const addressItem = getAddressFormSubdistrictID(addressList, subdistricID)
    onUpdateData(addressItem, 'address_item')
  }

  const FooterContent = (
    <Space size="middle" align="baseline">
      <PrimaryButton text="ยกเลิก" btnType="Cancel" onClick={onCloseDrawer} />
      <PrimaryButton text="ยืนยันการสร้าง" onClick={onCreate} />
    </Space>
  )

  const ConfirmCreateDailog = (
    <ConfirmDailog
      title="ยืนยันสร้างผู้ใช้งาน"
      description="คุณแน่ใจที่จะสร้างผู้ใช้งานหรือไม่?"
      confirmBtnTxt="อนุมัติ"
      isShow={confirmDialog}
      handleOk={onCreate}
      handleCancel={() => setConfirmDialog(false)}
      loading={event.IsLoading}
    />
  )

  const getAddressOptions = (data: AddressData[]) => {
    const options = [] as OptionProps[]
    data &&
      data.forEach(item => {
        options.push({
          value: item.sub_district.id,
          //label: `${item.sub_district.name} / ${item.district.name} /  ${item.province.name} `,
          label: (
            <AddressWrapper>
              <Row gutter={[1, 1]}>
                <Col span={8}>{item.sub_district.name} </Col>
                <Col span={8}>{item.district.name} </Col>
                <Col span={8}>{item.province.name}</Col>
              </Row>
            </AddressWrapper>
          ),
        })
      })
    return options
  }

  const title = <Title>เพิ่มผู้ใช้งาน</Title>

  const userInfoForm = (
    <BoxWrapper title="ข้อมูลผู้ใช้งาน">
      <Row justify="space-between" gutter={[16, 0]}>
        <Col md={12} xs={24}>
          <AppInput
            name="qr_name"
            label="Partner QR Name"
            placeHolder="ระบุ Partner QR Name เช่น RS , Radio , TV"
            isRequired={isRequired('qr_name')}
            isError={isError('qr_name')}
            value={formData.qr_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="email"
            label="อีเมลสำหรับเข้าใช้ระบบ"
            errorMsg={isError('email_format') ? 'รูปแบบอีเมลให้ถูกต้อง' : 'อีเมล'}
            isRequired={isRequired('email')}
            isError={isError('email') || isError('email_format')}
            value={formData.email}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="first_name"
            label="ชื่อ"
            isRequired={isRequired('first_name')}
            isError={isError('first_name')}
            value={formData.first_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="last_name"
            label="นามสกุล"
            isRequired={isRequired('last_name')}
            isError={isError('last_name')}
            value={formData.last_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="corporate_name"
            label="ชื่อนิติบุคคล"
            isRequired={isRequired('corporate_name')}
            isError={isError('corporate_name')}
            value={formData.corporate_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="corporate_no"
            label="เลขทะเบียนนิติบุคคล"
            isRequired={isRequired('corporate_no')}
            isError={isError('corporate_no')}
            value={formData.corporate_no}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="mobile"
            label="เบอร์โทรศัพท์"
            isRequired={isRequired('mobile')}
            isError={isError('mobile')}
            value={formData.mobile}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={24} xs={24}>
          <AppInput
            name="address"
            label="ที่อยู่"
            isRequired={isRequired('address')}
            isError={isError('address')}
            value={formData.address}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppAutocomplete
            labelColor="#000"
            name="postcode"
            label="รหัสไปรษณีย์"
            placeHolder="ระบุรหัสไปรษณีย์ 5 หลัก"
            isRequired={isRequired('postcode')}
            isError={isError('postcode')}
            value={formData.postcode}
            onChange={val => onSelectAddress(val)}
            onSearch={val => onUpdateData(val, 'postcode')}
            options={formData.postcode.length === 5 ? getAddressOptions(addressList) : []}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="sub_district_id"
            label="แขวง/ตำบล"
            isRequired={isRequired('address_item')}
            isError={isError('address_item')}
            value={formData.address_item?.sub_district.name}
            onChange={onUpdateData}
            resProps={{ disabled: true }}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="district_id"
            label="เขต/อำเภอ"
            isRequired={isRequired('address_item')}
            isError={isError('address_item')}
            value={formData.address_item?.district.name}
            onChange={onUpdateData}
            resProps={{ disabled: true }}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="province_id"
            label="จังหวัด"
            isRequired={isRequired('address_item')}
            isError={isError('address_item')}
            value={formData.address_item?.province.name}
            onChange={onUpdateData}
            resProps={{ disabled: true }}
          />
        </Col>
      </Row>
    </BoxWrapper>
  )

  const accountForm = (
    <BoxWrapper title="ข้อมูลด้านการเงิน">
      <Row justify="space-between" gutter={[16, 0]}>
        <Col md={12} xs={24}>
          <AppSelect
            name="bank_name"
            label="ธนาคาร"
            isRequired={isRequired('bank_name')}
            isError={isError('bank_name')}
            value={formData.bank_name}
            onChange={onUpdateData}
            menu={menuBankAccount}
          />
        </Col>
        {/* <Col md={12} xs={24}>
          <AppInput
            name="bank_branch"
            label="สาขา"
            isRequired={isRequired('bank_branch')}
            isError={isError('bank_branch')}
            value={formData.bank_branch}
            onChange={onUpdateData}
          />
        </Col> */}
        <Col md={12} xs={24}>
          <AppInput
            name="bank_account_name"
            label="ชื่อบัญชี"
            isRequired={isRequired('bank_account_name')}
            isError={isError('bank_account_name')}
            value={formData.bank_account_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="bank_account_no"
            label="เลขที่บัญชี"
            isRequired={isRequired('bank_account_no')}
            isError={isError('bank_account_no')}
            value={formData.bank_account_no}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="commission"
            label="เรทคอมมิชชั่น"
            isRequired={true}
            isError={isError('commission')}
            value={formData.commission}
            onChange={onUpdateData}
            resProps={{ suffix: '%' }}
            type="number"
          />
        </Col>
      </Row>
    </BoxWrapper>
  )

  const getMenuPartnerCode = (data: { [key: string]: any }[]) => {
    const menu = [] as { name: any; value: any }[]
    data &&
      data.forEach(item => {
        menu.push({
          name: `${Object.keys(item)} - ${Object.values(item)}`,
          value: Object.keys(item)[0],
        })
      })

    return menu
  }

  const parentForm = (
    <BoxWrapper title="สร้าง  Partner QR Code">
      <Row justify="space-between" gutter={[16, 0]}>
        <Col md={24} xs={24}>
          {/* <AppSelect
            name="user_partner_id"
            label="กำหนดหัวหน้างาน"
            placeHolder="เลือกหัวหน้างาน"
            isRequired={isRequired('user_partner_id')}
            isError={isError('user_partner_id')}
            value={formData.user_partner_id}
            onChange={onUpdateData}
            menu={menuBankAccount}
          /> */}
          <AppPartnerStructer
            categories={partnerStructer}
            value={formData?.select_parents}
            name="select_parents"
            isRequired={isRequired('select_parents')}
            isError={isError('select_parents')}
            label="กำหนดหัวหน้างาน"
            onChange={onUpdateData}
            hasLastchild={false}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppSelect
            name="channel_code"
            label="Channel Code"
            isRequired={isRequired('channel_code')}
            isError={isError('channel_code')}
            value={formData.channel_code}
            onChange={onUpdateData}
            menu={getMenuPartnerCode(partnerCodes.channel_code)}
            disabled={!isLevel1}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppSelect
            name="industry_code"
            label="Industry Code"
            isRequired={isRequired('industry_code')}
            isError={isError('industry_code')}
            value={formData.industry_code}
            onChange={onUpdateData}
            menu={getMenuPartnerCode(partnerCodes.industry_code)}
            disabled={!isLevel1}
          />
        </Col>

        {formData?.select_parents && (
          <Col md={12} xs={24}>
            <AppInput
              name="company_code"
              label="Level1-Company Code"
              placeHolder="ระบุ Company Code 2 ตัวอักษร"
              isRequired={isRequired('company_code')}
              isError={isError('company_code')}
              value={formData.company_code}
              onChange={onUpdateData}
              resProps={{
                disabled: !isLevel1,
              }}
            />
          </Col>
        )}
      </Row>
    </BoxWrapper>
  )

  return (
    <DetailDrawer
      title={title}
      onCloseDrawer={onCloseDrawer}
      visible={visible}
      footer={FooterContent}
      width="85%"
      maskClosable={true}
    >
      <LoadingView isShow={detailEvent.IsLoading} />
      <EventModal event={[event]} enable={true} successCalback={onEventSuccess} errorCalback={clearEvent} />
      <Row gutter={[16, 16]}>
        <Col md={24} xs={24}>
          {parentForm}
        </Col>
        <Col md={24} xs={24}>
          {userInfoForm}
        </Col>
        <Col md={24} xs={24}>
          {accountForm}
        </Col>
      </Row>
      {ConfirmCreateDailog}
    </DetailDrawer>
  )
}

const Title = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
`

const AddressWrapper = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
  @media only screen and (max-width: 720px) {
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectActionEvent(state),
    detail: selectDetail(state),
    detailEvent: selectDetailEvent(state),
    loginData: selectLoginData(state),
    addressList: selectAddressData(state),
    partnerStructer: selectPartnerStructer(state),
    partnerCodes: selectPartnerCode(state),
    partnerInfo: selectPartnerInfo(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(FormCreate)
