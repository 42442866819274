import React, { useState } from 'react'
import { Button, Row, Col, Modal, ModalBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { openUserSidebarAction } from 'Store/chat/actions'
import { setFullUserAction } from 'Store/chat/actions'

//import images
import user from '../../../assets/images/users/avatar-4.jpg'
import { selectChatActiveUser, selectChatUsers } from 'Store/chat/selector'
import { selectUserSidebar } from 'Store/chat/selector'
import { ProfileAvatar } from 'Components/avatar'
import { selectUserProfile } from 'Store/user/selector'
import { UserType } from 'helpers/constanst'
import { findUserById } from 'Store/chat/function'
import QrCodeModal from './QrCodeModal'

function UserHead(props) {
  const { userProfile } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownOpen1, setDropdownOpen1] = useState(false)
  const [Callmodal, setCallModal] = useState(false)
  const [Videomodal, setVideoModal] = useState(false)
  const [Qrmodal, setQrModal] = useState(false)
  const isAgent = userProfile?.user_type === UserType.AGENT

  const toggle = () => setDropdownOpen(!dropdownOpen)
  const toggle1 = () => setDropdownOpen1(!dropdownOpen1)
  const toggleCallModal = () => setCallModal(!Callmodal)
  const toggleVideoModal = () => setVideoModal(!Videomodal)
  const toggleQrModal = () => setCallModal(!Qrmodal)

  const openUserSidebar = e => {
    e.preventDefault()
    props.openUserSidebarAction()
  }

  function closeUserChat(e) {
    e.preventDefault()
    const userChat = document.getElementsByClassName('user-chat')
    if (userChat) {
      userChat[0].classList.remove('user-chat-show')
    }
  }

  function deleteMessage() {
    const allUsers = props.users
    const copyallUsers = allUsers
    const activeUser = findUserById(copyallUsers, props.active_user)
    activeUser.messages = []

    props.setFullUserAction(copyallUsers)
  }

  const activeUser = findUserById(props.users, props.active_user)

  return (
    <React.Fragment>
      <QrCodeModal visible={Qrmodal} onCloseDrawer={() => setQrModal(false)} activeUser={activeUser} />
      <div className="p-3 p-lg-4 border-bottom">
        <Row className="align-items-center">
          <Col sm={isAgent ? 10 : 12} xs={isAgent ? 10 : 12}>
            <div className="d-flex align-items-center">
              <div className="d-block d-lg-none me-2 ms-0">
                <Link
                  to="#"
                  onClick={e => closeUserChat(e)}
                  className="user-chat-remove text-muted font-size-16 p-2"
                >
                  <i className="ri-arrow-left-s-line"></i>
                </Link>
              </div>
              {activeUser?.profilePicture !== 'Null' ? (
                <div className="me-3 ms-0">
                  <ProfileAvatar size={45} name={activeUser?.name} logo={activeUser?.profilePicture} />
                </div>
              ) : (
                <div className="chat-user-img align-self-center me-3">
                  <div className="avatar-xs">
                    <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                      {activeUser?.name.charAt(0)}
                    </span>
                  </div>
                </div>
              )}

              <div className="flex-1 overflow-hidden">
                <h5 className="font-size-16 mb-0 text-truncate">
                  <Link to="#" onClick={e => openUserSidebar(e)} className="text-reset user-profile-show">
                    {activeUser?.name}
                  </Link>
                  {(() => {
                    switch (activeUser?.status || '') {
                      case 'online':
                        return (
                          <>
                            <i className="ri-record-circle-fill font-size-10 text-success d-inline-block ms-1"></i>
                          </>
                        )

                      case 'away':
                        return (
                          <>
                            <i className="ri-record-circle-fill font-size-10 text-warning d-inline-block ms-1"></i>
                          </>
                        )

                      case 'offline':
                        return (
                          <>
                            <i className="ri-record-circle-fill font-size-10 text-secondary d-inline-block ms-1"></i>
                          </>
                        )

                      default:
                        return
                    }
                  })()}
                  {isAgent && activeUser?.userSocket?.agentQr && (
                    <span className="agent-qr-text" onClick={() => setQrModal(true)}>
                      {' '}
                      &nbsp;- {activeUser?.userSocket?.agentQr}
                    </span>
                  )}
                </h5>
              </div>
            </div>
          </Col>
          {isAgent && (
            <Col sm={2} xs={2}>
              <ul className="list-inline user-chat-nav text-end mb-0">
                {/* <li className="list-inline-item">
                  <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle color="none" className="btn nav-btn " type="button">
                      <i className="ri-search-line"></i>
                    </DropdownToggle>
                    <DropdownMenu className="p-0 dropdown-menu-end dropdown-menu-md">
                      <div className="search-box p-2">
                        <Input type="text" className="form-control bg-light border-0" placeholder="Search.." />
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </li> */}
                {/* <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                  <button type="button" onClick={toggleCallModal} className="btn nav-btn">
                    <i className="ri-phone-line"></i>
                  </button>
                </li>
                <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                  <button type="button" onClick={toggleVideoModal} className="btn nav-btn">
                    <i className="ri-vidicon-line"></i>
                  </button>
                </li> */}
                {isAgent && (
                  <li className="list-inline-item d-lg-inline-block">
                    <Button
                      type="button"
                      color="none"
                      onClick={e => openUserSidebar(e)}
                      className="nav-btn user-profile-show"
                    >
                      <i className="ri-user-2-line"></i>
                    </Button>
                  </li>
                )}
              </ul>
            </Col>
          )}
        </Row>
      </div>

      {/* Start Audiocall Modal */}
      <Modal tabIndex="-1" isOpen={Callmodal} toggle={toggleCallModal} centered>
        <ModalBody>
          <div className="text-center p-4">
            <div className="avatar-lg mx-auto mb-4">
              <img src={user} alt="" className="img-thumbnail rounded-circle" />
            </div>

            <h5 className="text-truncate">Doris Brown</h5>
            <p className="text-muted">Start Audio Call</p>

            <div className="mt-5">
              <ul className="list-inline mb-1">
                <li className="list-inline-item px-2 me-2 ms-0">
                  <button
                    type="button"
                    className="btn btn-danger avatar-sm rounded-circle"
                    onClick={toggleCallModal}
                  >
                    <span className="avatar-title bg-transparent font-size-20">
                      <i className="ri-close-fill"></i>
                    </span>
                  </button>
                </li>
                <li className="list-inline-item px-2">
                  <button type="button" className="btn btn-success avatar-sm rounded-circle">
                    <span className="avatar-title bg-transparent font-size-20">
                      <i className="ri-phone-fill"></i>
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* Start VideoCall Modal */}
      <Modal tabIndex="-1" isOpen={Videomodal} toggle={toggleVideoModal} centered>
        <ModalBody>
          <div className="text-center p-4">
            <div className="avatar-lg mx-auto mb-4">
              <img src={user} alt="" className="img-thumbnail rounded-circle" />
            </div>

            <h5 className="text-truncate">Doris Brown</h5>
            <p className="text-muted">Start Video Call</p>

            <div className="mt-5">
              <ul className="list-inline mb-1">
                <li className="list-inline-item px-2 me-2 ms-0">
                  <button
                    type="button"
                    className="btn btn-danger avatar-sm rounded-circle"
                    onClick={toggleVideoModal}
                  >
                    <span className="avatar-title bg-transparent font-size-20">
                      <i className="ri-close-fill"></i>
                    </span>
                  </button>
                </li>
                <li className="list-inline-item px-2">
                  <button type="button" className="btn btn-success avatar-sm rounded-circle">
                    <span className="avatar-title bg-transparent font-size-20">
                      <i className="ri-vidicon-fill"></i>
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    active_user: selectChatActiveUser(state),
    userSidebar: selectUserSidebar(state),
    users: selectChatUsers(state),
    userProfile: selectUserProfile(state),
  }
}

export default connect(mapStateToProps, { openUserSidebarAction, setFullUserAction })(UserHead)
