import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, Modal, Row } from 'antd'
import Title from 'Components/text/Title'
import { PrimaryButton } from 'Components/button'
import { MyTheme } from 'Components/common/Theme'
import Label from 'Components/text/Label'
import Dragger from 'antd/lib/upload/Dragger'
import { InboxOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/lib/upload'

interface Props {
  isShow: boolean
  handleOk?: (file: any) => void
  handleCancel?: () => void
  title?: string
  titleSecond?: string
  confirmBtnTxt?: string
  cancelBtnTxt?: string
  loading?: boolean
  description?: string
}

const ConfirmImport = (props: Props) => {
  const {
    isShow = false,
    handleOk = () => {},
    handleCancel = () => {},
    title = 'ยืนยันการนำเช้า',
    confirmBtnTxt = 'ลบ',
    cancelBtnTxt = 'ยกเลิก',
    loading = false,
    description = '',
    titleSecond = '',
  } = props

  const [fileAttach, setFileAttach] = useState([] as any[])

  useEffect(() => {
    return () => {
      setFileAttach([])
    }
  }, [isShow])

  const onSubmit = () => {
    handleOk(fileAttach[0])
  }

  const footer = (
    <FooterStyled>
      <Row justify="center" gutter={16}>
        <Col md={8} xs={10}>
          <PrimaryButton text={cancelBtnTxt} btnType="Cancel" onClick={handleCancel} />
        </Col>
        <Col md={8} xs={10}>
          <PrimaryButton
            text={confirmBtnTxt}
            onClick={onSubmit}
            loading={loading}
            disabled={fileAttach.length < 1}
          />
        </Col>
      </Row>
    </FooterStyled>
  )

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    beforeUpload: (file: RcFile) => {
      const newFile = [file]
      setFileAttach(newFile)
      return false
    },
    onRemove: () => {
      setFileAttach([])
    },
    fileList: fileAttach,
    onChange() {},
    onDrop() {},
  }

  return (
    <ModalStyled visible={isShow} onCancel={handleCancel} footer={footer} centered>
      <ContentWrapper>
        <Title>{title}</Title>
        {titleSecond && <TitleSecond>{titleSecond}</TitleSecond>}
        <UploadWrapper>
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">คลิกหรือลากไฟล์เพื่ออัพโหลด</p>
            <TextStyled>{description}</TextStyled>
          </Dragger>
        </UploadWrapper>
      </ContentWrapper>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 6px;
  }
  .ant-modal-footer {
    border: none;
  }
`

const ContentWrapper = styled.div`
  text-align: center;
`

const FooterStyled = styled.div`
  margin-bottom: 15px;
`

const TextStyled = styled(Label)`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  text-align: left;
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
`
const UploadWrapper = styled.div`
  padding: 10px 0;
`

const TitleSecond = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.title1};
`

export default ConfirmImport
