import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { Checkbox, Col, Divider, Row, Space, Tabs } from 'antd'
import { PrimaryButton } from 'Components/button'
import { selectChatActiveUser, selectChatUsers } from 'Store/chat/selector'
import { findUserById } from 'Store/chat/function'
import { ProfileAvatar } from 'Components/avatar'
import styled from 'styled-components'
import Theme, { MyTheme } from 'Components/common/Theme'
import { CloseCircleOutlined, MailOutlined, SwapOutlined } from '@ant-design/icons'
import { AppDateItem, AppInput, AppSelect, AppTextArea } from 'Components/form'
import {
  formValidation,
  isFormError,
  isFormRequired,
  mergeArray,
  toDateQuery,
  validateEmail,
} from 'helpers/format'
import { CustomerStatus, menuSex } from 'helpers/constanst'
import LinkText from 'Components/text/LinkText'
import { getCustomerByUUIDAPI, updateCustomerAPI } from 'Store/customer/api'
import { GetCustomerReq, UpdateCustomerReq } from 'Store/customer/types'
import { selectLoginData } from 'Store/user/selector'
import { selectActionEvent, selectCustomerInfo, selectLastCreateTask } from 'Store/customer/selector'
import { clearEventAction } from 'Store/customer/actions'
import { ConfirmDailog, EventModal } from 'Components/modal'
import { cloneDeep } from 'lodash'
import Iframe from 'react-iframe'
import CustomerTaskDetail from '../Agent/CustomerTask/detail'
import { appConfig } from 'config'
import QrCodeModal from './UserChat/QrCodeModal'
const { TabPane } = Tabs

type Props = {
  isAgent?: boolean
  isNotAssign?: boolean
} & ReduxProps
const ChatForm: React.FunctionComponent<Props> = props => {
  const {
    loginData,
    active_user,
    users,
    isAgent = false,
    isNotAssign = false,
    customerInfo,
    actionEvent,
    lastCreateTask,
    onBookFriend,
  } = props
  const initForm = {
    first_name: '',
    last_name: '',
    birthday: '',
    branch_email: '',
    branch_detail: '',
    //branch_info: '',
    email: '',
    is_branch: false,
    mobile: '',
    note: '',
    sex: '',
    status: CustomerStatus.DRAFT,
    qr_reference: '',
  } as UpdateCustomerReq
  const nomalRequireFeild = ['first_name', 'last_name', 'mobile']
  const branchRequireFeild = ['branch_email']
  const [formError, setFormError] = useState([] as string[])
  const [confirmModal, setConfirmModal] = useState(false)
  const [returnModal, setReturnModal] = useState(false)
  const [endConversationModal, setEndConversationModal] = useState(false)
  const [taskDrawer, setTaskDrawer] = useState(false)
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const [Qrmodal, setQrModal] = useState(false)
  const dispatch = useDispatch()
  const activeUser = findUserById(users, active_user)
  const requireFeild = formData.is_branch
    ? mergeArray([nomalRequireFeild, branchRequireFeild])
    : nomalRequireFeild

  const onUpdateCustomer = (status: CustomerStatus): void => {
    setConfirmModal(false)
    const req: UpdateCustomerReq = {
      ...formData,
      birthday: formData.birthday ? toDateQuery(formData.birthday) : '',
      status: status,
      qr_reference: activeUser?.userSocket?.agentID || '',
    }
    dispatch(updateCustomerAPI(req, activeUser?.userID || '', loginData?.access_token || ''))
  }

  const onGetCustomerInfo = (): void => {
    const req: GetCustomerReq = {
      uuid: activeUser?.userID || '',
    }
    dispatch(getCustomerByUUIDAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    if (customerInfo) {
      const form = {
        birthday: customerInfo?.birthday,
        email: customerInfo?.email,
        first_name: customerInfo?.first_name,
        last_name: customerInfo?.last_name,
        mobile: customerInfo?.mobile,
        sex: customerInfo?.sex,
        note: customerInfo?.insure?.note,
        request_number: customerInfo?.insure?.request_number,
        is_branch: customerInfo?.insure?.is_branch,
        branch_email: customerInfo?.insure?.branch_email,
        branch_detail: customerInfo?.insure?.branch_detail,
        qr_reference: '',
      } as UpdateCustomerReq
      setFormData({ ...form })
    }
  }, [customerInfo])

  useEffect(() => {
    if (isAgent && !isNotAssign) {
      onGetCustomerInfo()
    }
  }, [active_user])

  useEffect(() => {
    setFormError([])
    setFormData(cloneDeep(initForm))
  }, [active_user])

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    if (formData.email) {
      if (!validateEmail(formData.email)) {
        error.push('email_format')
      }
    }
    if (formData.branch_email) {
      if (!validateEmail(formData.branch_email)) {
        error.push('branch_email_format')
      }
    }
    setFormError([...error])
    return error.length > 0 ? false : true
  }

  const onSaveDraft = () => {
    onUpdateCustomer(CustomerStatus.DRAFT)
  }

  const onOpenCase = () => {
    if (!validateForm()) return
    setConfirmModal(true)
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const onUpdateData = (value: any, key: string) => {
    if (key === 'mobile' && value.length > 10) return
    setFormData({
      ...formData,
      [key]: value,
    })
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventSuccess = (): void => {
    onGetCustomerInfo()
    clearEvent()
    if (lastCreateTask?.id) {
      setTaskDrawer(true)
    }
  }

  const onReturn = () => {
    setReturnModal(false)
    onBookFriend('return')
  }

  const onEndChat = () => {
    setEndConversationModal(false)
    onBookFriend('end')
  }

  const ConfirmOpenCaseModal = (
    <ConfirmDailog
      title={`คุณแน่ใจหรือว่าต้องการเปิดเคสใหม่ ?`}
      description="กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนยืนยันการเปิดเคส"
      isShow={confirmModal}
      confirmBtnTxt="ยืนยันเปิดเคส"
      handleOk={() => onUpdateCustomer(CustomerStatus.OPEN)}
      handleCancel={() => setConfirmModal(false)}
    />
  )

  const ConfirmReturnModal = (
    <ConfirmDailog
      title={`คุณต้องการโอนงานไปยังห้องแชททั้งหมดใช่หรือไม่ ?`}
      description=""
      isShow={returnModal}
      confirmBtnTxt="ยืนยัน"
      handleOk={onReturn}
      handleCancel={() => setReturnModal(false)}
    />
  )

  const ConfirmEndConversationModal = (
    <ConfirmDailog
      title={`คุณต้องการจบการสนทนากับลูกค้าใช่หรือไม่ ?`}
      description="หากจบการสนทนาแชทจะหายไปจากงานที่มอบหมายให้ฉัน"
      isShow={endConversationModal}
      confirmBtnTxt="ยืนยัน"
      handleOk={onEndChat}
      handleCancel={() => setEndConversationModal(false)}
    />
  )

  const cutomerInfo = () => {
    return (
      <Row justify="start" align="top" gutter={[8, 8]}>
        <Col>
          <ProfileAvatar size={45} name={activeUser?.name} logo={activeUser?.profilePicture} />
        </Col>
        <Col>
          <Space direction="vertical" size="small">
            <QrCodeModal visible={Qrmodal} onCloseDrawer={() => setQrModal(false)} activeUser={activeUser} />
            <Name>{activeUser?.name}</Name>
            {activeUser?.userSocket?.agentQr ? (
              <QrCode onClick={() => setQrModal(true)}>- {activeUser?.userSocket?.agentQr}</QrCode>
            ) : (
              ''
            )}
            {activeUser?.userSocket?.email && (
              <Email>
                <MailOutlined /> &nbsp;
                {activeUser?.userSocket?.email}
              </Email>
            )}

            {/* <Text>วันที่เข้าร่วม ****</Text> */}
            <Space size="small">
              <PrimaryButton
                text={'โอนงาน'}
                onClick={() => setReturnModal(true)}
                size="small"
                btnType="Cancel"
                icon={<SwapOutlined />}
              />
              <PrimaryButton
                text={'จบการสนทนา'}
                onClick={() => setEndConversationModal(true)}
                size="small"
                btnType="Reject"
                icon={<CloseCircleOutlined />}
              />
            </Space>
          </Space>
        </Col>
      </Row>
    )
  }

  const lifeInsuranceForm = () => {
    return (
      <Row justify="start" align="top" gutter={8}>
        <Col span={24}>
          <Name>ข้อมูลลูกค้า</Name>
        </Col>
        <Col span={24}>
          <AppInput
            name="first_name"
            label="ชื่อ"
            isRequired={isRequired('first_name')}
            isError={isError('first_name')}
            value={formData.first_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col span={24}>
          <AppInput
            name="last_name"
            label="นามสกุล"
            isRequired={isRequired('last_name')}
            isError={isError('last_name')}
            value={formData.last_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col span={24}>
          <AppSelect
            name="sex"
            label="เพศ"
            isRequired={isRequired('sex')}
            isError={isError('sex')}
            value={formData.sex}
            onChange={onUpdateData}
            menu={menuSex}
          />
        </Col>
        <Col span={24}>
          <AppDateItem
            label="วันเดือนปีเกิด"
            placeHolder="วันเดือนปีเกิด"
            isRequired={isRequired('birthday')}
            isError={isError('birthday')}
            onChange={(date: string) => onUpdateData(date, 'birthday')}
            date={formData.birthday}
            allowClear={false}
            picker="date"
          />
        </Col>
        <Col span={24}>
          <AppInput
            name="mobile"
            label="เบอร์โทรศัพท์"
            placeHolder="ตัวอย่าง 0888888888"
            isRequired={isRequired('mobile')}
            isError={isError('mobile')}
            value={formData.mobile}
            onChange={onUpdateData}
          />
        </Col>
        <Col span={24}>
          <AppInput
            name="email"
            label="อีเมล"
            errorMsg={isError('email_format') ? 'รูปแบบอีเมลให้ถูกต้อง' : 'อีเมล'}
            isRequired={isRequired('email') || formData.email !== ''}
            isError={isError('email') || isError('email_format')}
            value={formData.email}
            onChange={onUpdateData}
          />
        </Col>
        <Col span={24}>
          <AppTextArea
            label="หมายเหตุ"
            placeHolder="หมายเหตุ (ถ้ามี)"
            isRequired={isRequired('note')}
            isError={isError('note')}
            name="note"
            rows={2}
            value={formData.note}
            onChange={onUpdateData}
          />
        </Col>
        <Col span={24}>
          <CheckBoxWrapper>
            <Space size="small">
              <Checkbox
                onChange={e => onUpdateData(e.target.checked, 'is_branch')}
                checked={formData.is_branch || false}
              />
              ต้องการส่งข้อมูลไปยังสาขา
              <a href={appConfig.servicePointLink} target="_bank">
                <LinkText color={Theme.colors.primary} onClick={() => {}}>
                  (Service Point)
                </LinkText>
              </a>
            </Space>
          </CheckBoxWrapper>
        </Col>
        {formData.is_branch && (
          <>
            <Col span={24}>
              <AppInput
                name="branch_email"
                label="อีเมลสาขา"
                placeHolder="ตัวอย่าง : mail001@email.scb.co.th"
                errorMsg={isError('branch_email_format') ? 'รูปแบบอีเมลให้ถูกต้อง' : 'อีเมล'}
                isRequired={isRequired('branch_email')}
                isError={isError('branch_email') || isError('branch_email_format')}
                value={formData.branch_email}
                onChange={onUpdateData}
              />
            </Col>
            <Col span={24}>
              <AppInput
                name="branch_detail"
                label="ข้อมูลสาขา"
                isRequired={isRequired('branch_detail')}
                isError={isError('branch_detail')}
                value={formData.branch_detail}
                onChange={onUpdateData}
              />
            </Col>
          </>
        )}
        <Col span={24}>
          <Row justify="center" gutter={[8, 8]}>
            <Col span={12}>
              <PrimaryButton text={'บันทึกแบบร่าง'} onClick={onSaveDraft} btnType="Cancel" />
            </Col>
            <Col span={12}>
              <PrimaryButton text={'เปิดเคส'} onClick={onOpenCase} />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const renderCustomerInfo = () => {
    return (
      <Row>
        <Col span={24}>{cutomerInfo()}</Col>
        <Col span={24}>
          <Divider />
        </Col>
        <Col span={24}>{lifeInsuranceForm()}</Col>
      </Row>
    )
  }

  const DetailDrawer = (
    <CustomerTaskDetail
      id={lastCreateTask?.id || 0}
      visible={taskDrawer}
      onCloseDrawer={() => setTaskDrawer(false)}
      onSuccess={onEventSuccess}
    />
  )

  return (
    <div className="profile-info">
      <EventModal
        event={[actionEvent]}
        enable={true}
        successCalback={onEventSuccess}
        errorCalback={clearEvent}
      />
      {ConfirmOpenCaseModal}
      {ConfirmReturnModal}
      {ConfirmEndConversationModal}
      {DetailDrawer}
      <Row>
        <Col span={24}>
          <ContentWrapper>
            <Tabs defaultActiveKey="1">
              <TabPane tab={`ข้อมูลลูกค้า`} key="1">
                {renderCustomerInfo()}
              </TabPane>
              {/* <TabPane tab={`LIFE PLAN`} key="2">
                <WebIframe url={appConfig.fwdEndpoint} id="myId" className="web-iframe" position="relative" />
              </TabPane> */}
            </Tabs>
          </ContentWrapper>
        </Col>
      </Row>
    </div>
  )
}

const ContentWrapper = styled.div`
  padding: 0 10px;
`

const Name = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.title3};
`
const CheckBoxWrapper = styled.div`
  padding-bottom: 10px;
`

const WebIframe = styled(Iframe)`
  width: 100%;
`

const QrCode = styled.span`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  cursor: pointer;
`

const Email = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  margin-bottom: 10px;
`

const mapStateToProps = (state: RootState): any => {
  return {
    users: selectChatUsers(state),
    active_user: selectChatActiveUser(state),
    loginData: selectLoginData(state),
    customerInfo: selectCustomerInfo(state),
    actionEvent: selectActionEvent(state),
    lastCreateTask: selectLastCreateTask(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(ChatForm)
